const WarningIcon = ({ fill = '#D9650C', width = '24', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_3741_16646"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3741_16646)">
        <path
          d="M11.9996 22.0251C11.7329 22.0251 11.4788 21.9751 11.2371 21.8751C10.9954 21.7751 10.7746 21.6334 10.5746 21.4501L2.54961 13.4251C2.36628 13.2251 2.22461 13.0043 2.12461 12.7626C2.02461 12.5209 1.97461 12.2668 1.97461 12.0001C1.97461 11.7334 2.02461 11.4751 2.12461 11.2251C2.22461 10.9751 2.36628 10.7584 2.54961 10.5751L10.5746 2.5501C10.7746 2.3501 10.9954 2.20426 11.2371 2.1126C11.4788 2.02093 11.7329 1.9751 11.9996 1.9751C12.2663 1.9751 12.5246 2.02093 12.7746 2.1126C13.0246 2.20426 13.2413 2.3501 13.4246 2.5501L21.4496 10.5751C21.6496 10.7584 21.7954 10.9751 21.8871 11.2251C21.9788 11.4751 22.0246 11.7334 22.0246 12.0001C22.0246 12.2668 21.9788 12.5209 21.8871 12.7626C21.7954 13.0043 21.6496 13.2251 21.4496 13.4251L13.4246 21.4501C13.2413 21.6334 13.0246 21.7751 12.7746 21.8751C12.5246 21.9751 12.2663 22.0251 11.9996 22.0251ZM11.9996 20.0251L20.0246 12.0001L11.9996 3.9751L3.97461 12.0001L11.9996 20.0251ZM10.9996 13.0001H12.9996V7.0001H10.9996V13.0001ZM11.9996 16.0001C12.2829 16.0001 12.5204 15.9043 12.7121 15.7126C12.9038 15.5209 12.9996 15.2834 12.9996 15.0001C12.9996 14.7168 12.9038 14.4793 12.7121 14.2876C12.5204 14.0959 12.2829 14.0001 11.9996 14.0001C11.7163 14.0001 11.4788 14.0959 11.2871 14.2876C11.0954 14.4793 10.9996 14.7168 10.9996 15.0001C10.9996 15.2834 11.0954 15.5209 11.2871 15.7126C11.4788 15.9043 11.7163 16.0001 11.9996 16.0001Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default WarningIcon
