import Skeleton from 'react-loading-skeleton'
import useResize from '../../../hooks/useResize'
import { VIEWPORT_LG } from '../../../utils/constants'

const ScheduleCallBannerSkeleton = () => {
  const viewportSize = useResize()
  const isMobile = viewportSize[0] <= VIEWPORT_LG;
  return (
    <Skeleton
      className="mt-2 mb-2"
      count={1}
      width={isMobile ? 336 : '100%'}
      height={isMobile ? 112 : 64}
    />
  )
}

export default ScheduleCallBannerSkeleton
