const HutritIconThree = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={104}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="#004949"
      d="M22.225 28.51h-6.253v-8.186c-3.236 1.414-6.433 1.422-9.674.007v8.16H.02V8.143h6.259v.592c0 2.06.01 4.118-.008 6.177 0 .389.097.6.475.76 2.887 1.23 6.744 1.133 9.226-.257v-7.27h6.254v20.364ZM40.622 8.128h6.242v20.365H40.63v-2.697c-1.117 1.384-2.398 2.228-3.944 2.654-3.715 1.02-7.384-.431-9.084-3.605a9.187 9.187 0 0 1-1.061-4.297c-.038-3.909-.013-7.82-.013-11.728v-.666h6.234v.62c0 3.418-.006 6.836 0 10.255 0 1.047.138 2.073.774 2.956.936 1.3 2.668 1.83 4.36 1.347 1.544-.44 2.564-1.716 2.659-3.42.061-1.11.056-2.225.058-3.338.008-2.607 0-5.214 0-7.82l.009-.626ZM52.354 13.322h-2.461V8.125h2.445V3.151h6.25v4.943h4.117v5.202h-4.099v7.797c0 1.656.429 2.09 2.063 2.09h2.062v5.352c-2.18-.072-4.375.236-6.493-.519-2.283-.811-3.496-2.487-3.76-4.843-.124-1.124-.11-2.257-.124-3.39-.013-2.13 0-4.262 0-6.461ZM104 28.537c-2-.074-4.003.184-5.966-.36-2.534-.701-4.042-2.444-4.3-5.08-.105-1.067-.114-2.147-.114-3.222-.019-2.153-.01-4.307-.014-6.46 0-.015-.022-.028-.073-.09h-2.392v-5.18h2.436V3.158h6.303V8.09h4.072v5.214H99.9v8.047c0 1.35.488 1.825 1.856 1.827H104v5.358ZM72.474 28.51H66.23V8.141h6.246v3.269c1.647-2.234 3.656-3.514 6.374-3.533v6.672c-.775 0-1.526-.01-2.277 0-.32.012-.638.05-.952.114-1.998.39-2.99 1.491-3.094 3.531-.065 1.337-.051 2.679-.057 4.018-.008 1.889 0 3.777 0 5.667l.003.63ZM88.096 8.152v20.342h-6.233V8.152h6.233ZM81.857 6.276V.042h6.211v6.24l-6.211-.006ZM19.105 6.28a3.14 3.14 0 1 1 3.155-3.159 3.133 3.133 0 0 1-3.155 3.16ZM3.152 6.281a3.14 3.14 0 1 1-.023-6.28 3.14 3.14 0 0 1 .023 6.28Z"
    />
  </svg>
)
export default HutritIconThree
