import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { TAGS } from '../../../services/Tags'
import {
  getAllNotifications,
  hideNewNotificationsList,
} from '../../../services/Notifications'
import { token } from '../../../localStorage'
import dayjs from 'dayjs'
import Divider from '../../Divider'
import NotificationIcon from '../../../icons/NotificationIcon'
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon'
import Circle from '../../Circle'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../Button'
import useResize from '../../../hooks/useResize'
import {
  LIMIT_NEW_NOTIFICATIONS_TO_SHOW,
  LIMIT_OLD_NOTIFICATIONS_TO_SHOW,
  OUT_OF_THE_APP,
  TYPE_OF_NOTIFICATIONS,
  VIEWPORT_LG,
} from '../../../utils/constants'
import { PRIVATE_ROUTES } from '../../../Routes/routes'
import { useTranslation } from 'react-i18next'

const ListOfNotifications = ({
  openUserOptions,
  setOpenUserOptions,
  handleViewNotifications,
  newNotificationsListData,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const viewportSize = useResize()
  const navigate = useNavigate()
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  const { data: allNotificationsData = {} } = useQuery(
    [TAGS.all_notifications],
    () => getAllNotifications({ page: 0 }),
    {
      enabled: token !== undefined,
    }
  )

  const getAllIds = allNotificationsData?.notifications?.map(({ id }) => id)

  const hideNotificationsWithoutButton =
    allNotificationsData?.notifications?.filter(
      ({ notification_redirect }) => notification_redirect === null
    )

  const getIdsFromNotificationsWithoutButton =
    hideNotificationsWithoutButton?.map(({ id }) => id)

  const { mutate: hideNewNotificationsListMutate } = useMutation(
    [TAGS.hide_new_notification_list],
    hideNewNotificationsList,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TAGS.new_notification_list)
      },
    }
  )

  const renderNewNotifications = allNotificationsData?.notifications?.filter(
    ({ date }) => {
      const dateOfCreation = dayjs(date)
      return dayjs().isSame(dateOfCreation, 'date')
    }
  )

  const renderOldNotifications = allNotificationsData?.notifications?.filter(
    ({ date }) => {
      const dateOfCreation = dayjs(date)
      return dayjs().isAfter(dateOfCreation, 'date')
    }
  )

  const newNotificationsToShow =
    renderNewNotifications?.length >= 3
      ? renderNewNotifications?.slice(0, LIMIT_NEW_NOTIFICATIONS_TO_SHOW)
      : renderNewNotifications

  const oldNotificationsToShow =
    renderNewNotifications?.length >= 3
      ? []
      : renderOldNotifications?.slice(0, LIMIT_OLD_NOTIFICATIONS_TO_SHOW)

  const handleHideNotifications = () => {
    hideNewNotificationsListMutate({ idsNotifications: getAllIds || [] })
  }

  const handleRedirect = ({ url = '', id = 0, viewed_state }) => {
    if (!viewed_state) {
      hideNewNotificationsListMutate({ idsNotifications: [id] })
    }
    navigate(`${url}`)
    handleViewNotifications()
  }

  const renderNewNotificationsResponsive =
    viewportSize[0] <= VIEWPORT_LG
      ? renderNewNotifications
      : newNotificationsToShow
  const renderOldNotificationsResponsive =
    viewportSize[0] <= VIEWPORT_LG
      ? renderOldNotifications
      : oldNotificationsToShow

  useEffect(() => {
    if (OUT_OF_THE_APP) {
      return
    }
    hideNewNotificationsListMutate({
      idsNotifications: getIdsFromNotificationsWithoutButton || [],
    })
  }, [openUserOptions === true, OUT_OF_THE_APP])

  return (
    <section
      id="list-of-notifications"
      className={`collapse ${openUserOptions ? 'show' : ''}`}
    >
      <div className="notification-styles position-fixed border rounded bg-white shadow">
        <header className="position-relative p-2">
          <div
            className="position-absolute arrow-left-notifications-style ms-3 cursor-pointer"
            onClick={handleViewNotifications}
          >
            <ArrowLeftIcon />
          </div>
          <div className="d-flex justify-content-center">
            <span className="text-on-bg-primary text-center fw-bolder">
              {t('your_notifications')}
            </span>
          </div>
        </header>
        <Divider className="w-100 bg-on-bg-Tertiary" />
        <div className="d-flex justify-content-end align-items-end pe-2 pt-2 gap-2">
          <span
            className={`${
              newNotificationsListData?.length > 0
                ? 'text-on-bg-primary'
                : 'text-bg-CTA-primary-disabled'
            } font-extra-small cursor-pointer`}
            onClick={handleHideNotifications}
          >
            {t('mark_as_read')}
          </span>
          <span className="badge rounded-pill bg-bg-CTA-primary">
            {newNotificationsListData?.length > 0 &&
              newNotificationsListData?.length}
          </span>
        </div>
        {allNotificationsData?.notifications?.length > 0 ? (
          <div className="font-small all-notifications">
            {renderNewNotifications?.length > 0 && (
              <span className="text-on-bg-secondary p-2">{t('newTwo')}</span>
            )}
            <div className="mt-2">
              {renderNewNotificationsResponsive?.map(
                ({
                  id,
                  offer_date = '',
                  notification_date = '',
                  date = '',
                  description = '',
                  title = t('not_available'),
                  notification_type_id = 1,
                  redirect = false,
                  viewed_state = false,
                  url = '',
                  // img = '',
                }) => {
                  const isCompany = redirect
                  const today = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                  const dateStart = dayjs(today)
                  const dateOfCreation = dayjs(
                    offer_date || notification_date || date
                  )

                  const years = dateStart.diff(dateOfCreation, 'year')
                  const months = dateOfCreation
                    .subtract(years, 'year')
                    .diff(dateOfCreation, 'month')

                  const days = dateStart
                    .subtract(years, 'year')
                    .subtract(months, 'month')
                    .diff(dateOfCreation, 'day')

                  return (
                    <div key={id} className="mt-2">
                      <div
                        className={`${
                          viewed_state ? '' : 'bg-bg-tertiary'
                        } p-2 d-flex justify-content-between align-items-start`}
                      >
                        <div className="d-flex align-items-start gap-2">
                          <div className="d-flex align-items-center gap-2">
                            {!viewed_state && (
                              <Circle
                                className="bg-bg-CTA-primary"
                                width="8px"
                                height="8px"
                              ></Circle>
                            )}
                            {TYPE_OF_NOTIFICATIONS[notification_type_id]}
                          </div>
                          <div className="text-bg-brand d-flex flex-column font-small">
                            <span>{title}</span>

                            <span className="text-secondary font-extra-small">
                              {description}
                            </span>

                            <div style={{ width: '200px' }}>
                              {isCompany && (
                                <Button
                                  className={`${
                                    viewed_state ? '' : 'bg-bg-tertiary'
                                  } btn-white text-on-bg-primary border border-on-bg-primary mt-3 font-small`}
                                  onClick={() => {
                                    handleRedirect({ url, id, viewed_state })
                                    setOpenUserOptions(false)
                                  }}
                                >
                                  {t('review_now')}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <footer className="font-extra-small text-secondary d-flex flex-column gap-1">
                            <span>
                              {`${years !== 0 ? years : ''} ${
                                years !== 0 ? t('years') : ''
                              } ${months !== 0 ? months : ''} ${
                                months !== 0 ? t('months') : ''
                              } ${days !== 0 ? days : ''} ${
                                days !== 0 ? t('days') : ''
                              } ${
                                dayjs().isSame(dateOfCreation, 'date')
                                  ? t('today')
                                  : ''
                              }`}
                            </span>
                          </footer>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
              {renderOldNotifications?.length > 0 && (
                <span className="text-on-bg-secondary">{t('previous')}</span>
              )}
              {renderOldNotificationsResponsive?.map(
                ({
                  id,
                  offer_date = '',
                  notification_date = '',
                  date = '',
                  description = '',
                  title = t('not_available'),
                  notification_type_id = 0,
                  redirect = false,
                  viewed_state = false,
                  url,
                  // img = '',
                }) => {
                  const isCompany = redirect
                  const today = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                  const dateStart = dayjs(today)
                  const dateOfCreation = dayjs(
                    offer_date || notification_date || date
                  )

                  const years = dateStart.diff(dateOfCreation, 'year')
                  const months = dateOfCreation
                    .subtract(years, 'year')
                    .diff(dateOfCreation, 'month')

                  const days = dateStart
                    .subtract(years, 'year')
                    .subtract(months, 'month')
                    .diff(dateOfCreation, 'day')

                  return (
                    <div key={id} className="mt-2">
                      <div
                        className={`${
                          viewed_state ? '' : 'bg-bg-tertiary'
                        } p-2 d-flex justify-content-between align-items-start`}
                      >
                        <div className="d-flex align-items-start gap-2">
                          <div className="d-flex align-items-center gap-2">
                            {!viewed_state && (
                              <Circle
                                className="bg-bg-CTA-primary"
                                width="8px"
                                height="8px"
                              ></Circle>
                            )}
                            {TYPE_OF_NOTIFICATIONS[notification_type_id]}
                          </div>
                          <div className="text-bg-brand d-flex flex-column font-small">
                            <span>{title}</span>

                            <span className="text-secondary font-extra-small">
                              {description}
                            </span>

                            <div style={{ width: '200px' }}>
                              {isCompany && (
                                <Button
                                  className={`${
                                    viewed_state ? '' : 'bg-bg-tertiary'
                                  } btn-white text-on-bg-primary border border-on-bg-primary mt-3 font-small`}
                                  onClick={() => {
                                    handleRedirect({ url, id, viewed_state })
                                    setOpenUserOptions(false)
                                  }}
                                >
                                  {t('review_now')}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <footer className="font-extra-small text-secondary d-flex flex-column gap-1">
                            <span>
                              {`${years !== 0 ? years : ''} ${
                                years !== 0 ? t('years') : ''
                              } ${months !== 0 ? months : ''} ${
                                months !== 0 ? t('months') : ''
                              } ${days !== 0 ? days : ''} ${
                                days !== 0 ? t('days') : ''
                              } ${
                                dayjs().isSame(dateOfCreation, 'date')
                                  ? t('today')
                                  : ''
                              }`}
                            </span>
                          </footer>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
            <div className="d-lg-none d-flex align-items-center justify-content-center mt-5 pt-5">
              <span className="text-on-bg-secondary text-center">
                {t('there_are_no_more_notifications')}
              </span>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            <div
              style={{
                marginTop: isMobile ? '50px' : '',
              }}
            >
              <NotificationIcon />
            </div>
            <h3 className="text-on-bg-primary fs-3 fw-bolder mt-3">
              {t('no_notifications')}
            </h3>
            <span className="text-on-bg-primary text-center">
              {t(
                'here_we_will_notify_you_when_companies_contact_you_and_information_relevant_to_you'
              )}
            </span>
            <div
              style={{
                marginTop: isMobile ? '100px' : '12px',
              }}
            >
              <Button onClick={handleViewNotifications}>
                Volver al inicio
              </Button>
            </div>
          </div>
        )}
        {allNotificationsData?.notifications?.length > 3 && (
          <div className="d-none d-lg-flex align-items-center justify-content-center mb-3 mt-4">
            <Link
              className="text-on-bg-primary text-center"
              onClick={handleViewNotifications}
              to={PRIVATE_ROUTES.all_notifications}
            >
              {t('review_all')}
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}

export default ListOfNotifications
