const CopyExtraSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <mask
        id="mask0_3330_13153"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.28418" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3330_13153)">
        <path
          d="M3.33333 14.951C2.96667 14.951 2.65278 14.8205 2.39167 14.5593C2.13056 14.2982 2 13.9843 2 13.6177V4.28434H3.33333V13.6177H10.6667V14.951H3.33333ZM6 12.2843C5.63333 12.2843 5.31944 12.1538 5.05833 11.8927C4.79722 11.6316 4.66667 11.3177 4.66667 10.951V2.95101C4.66667 2.58434 4.79722 2.27045 5.05833 2.00934C5.31944 1.74823 5.63333 1.61768 6 1.61768H12C12.3667 1.61768 12.6806 1.74823 12.9417 2.00934C13.2028 2.27045 13.3333 2.58434 13.3333 2.95101V10.951C13.3333 11.3177 13.2028 11.6316 12.9417 11.8927C12.6806 12.1538 12.3667 12.2843 12 12.2843H6ZM6 10.951H12V2.95101H6V10.951Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default CopyExtraSmallIcon
