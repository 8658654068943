const GlittersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    fill="none"
    {...props}
  >
    <path
      fill="#157A08"
      d="m9.5 4-.625-1.375L7.5 2l1.375-.625L9.5 0l.625 1.375L11.5 2l-1.375.625L9.5 4Zm0 7-.625-1.375L7.5 9l1.375-.625L9.5 7l.625 1.375L11.5 9l-1.375.625L9.5 11Zm-5-1.5L3.25 6.75.5 5.5l2.75-1.25L4.5 1.5l1.25 2.75L8.5 5.5 5.75 6.75 4.5 9.5Zm0-2.425L5 6l1.075-.5L5 5l-.5-1.075L4 5l-1.075.5L4 6l.5 1.075Z"
    />
  </svg>
)
export default GlittersIcon
