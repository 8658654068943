const StoreIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={48}
      height={48}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h48v48H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#01A76A"
        d="M42.05 22.1V38c0 1.1-.392 2.042-1.175 2.825C40.092 41.608 39.15 42 38.05 42h-28c-1.1 0-2.042-.392-2.825-1.175C6.442 40.042 6.05 39.1 6.05 38V22.1c-.767-.7-1.358-1.6-1.775-2.7-.417-1.1-.425-2.3-.025-3.6L6.35 9a4.419 4.419 0 0 1 1.425-2.15A3.61 3.61 0 0 1 10.15 6h27.8c.9 0 1.683.275 2.35.825.667.55 1.15 1.275 1.45 2.175l2.1 6.8c.4 1.3.392 2.483-.025 3.55a7.901 7.901 0 0 1-1.775 2.75ZM28.45 20c.9 0 1.583-.308 2.05-.925A2.73 2.73 0 0 0 31.05 17l-1.1-7h-3.9v7.4c0 .7.233 1.308.7 1.825.467.517 1.033.775 1.7.775Zm-9 0c.767 0 1.392-.258 1.875-.775a2.584 2.584 0 0 0 .725-1.825V10h-3.9l-1.1 7c-.133.8.042 1.5.525 2.1.483.6 1.108.9 1.875.9Zm-8.9 0c.6 0 1.125-.217 1.575-.65.45-.433.725-.983.825-1.65l1.1-7.7h-3.9l-2 6.7c-.2.667-.092 1.383.325 2.15.417.767 1.108 1.15 2.075 1.15Zm27 0c.967 0 1.667-.383 2.1-1.15.433-.767.533-1.483.3-2.15l-2.1-6.7h-3.8l1.1 7.7c.1.667.375 1.217.825 1.65.45.433.975.65 1.575.65Zm-27.5 18h28V23.9c-.167.067-.275.1-.325.1h-.175c-.9 0-1.692-.15-2.375-.45-.683-.3-1.358-.783-2.025-1.45-.6.6-1.283 1.067-2.05 1.4-.767.333-1.583.5-2.45.5-.9 0-1.742-.167-2.525-.5a6.515 6.515 0 0 1-2.075-1.4 6.097 6.097 0 0 1-1.975 1.4c-.75.333-1.558.5-2.425.5-.967 0-1.842-.167-2.625-.5a6.515 6.515 0 0 1-2.075-1.4c-.7.7-1.392 1.192-2.075 1.475-.683.283-1.458.425-2.325.425h-.225a.507.507 0 0 1-.275-.1V38Z"
      />
    </g>
  </svg>
)
export default StoreIcon
