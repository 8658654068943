import { default as ReactSelect, components } from 'react-select'
import { TAGS } from '../services/Tags'
import { useQuery } from '@tanstack/react-query'
import { getListOfSkillsCategories } from '../services/talents'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SelectSkills = ({
  setSelectedSkills,
  defaultSelectedSkills = null,
  amountSkills = 12,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState({ optionSelected: defaultSelectedSkills })
  const errorMaxSkills = state?.optionSelected?.length > amountSkills

  const { data: skillsCategoriesData = [] } = useQuery(
    [TAGS.get_skills_categories],
    getListOfSkillsCategories
  )

  const transformData = skillsCategoriesData?.map((props) => {
    return {
      value: props.id,
      label: props.skill.name,
    }
  })

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="mycheck"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    )
  }

  const handleChange = (selected) => {
    setState({
      optionSelected: selected,
    })
  }

  useEffect(() => {
    setSelectedSkills(state.optionSelected)
  }, [state])

  //   const customStyles = {
  //     option: (provided) => ({
  //       ...provided,
  //       display: 'flex',
  //       alignItems: 'center',
  //     }),
  //     singleValue: (provided) => ({
  //       ...provided,
  //       display: 'flex',
  //       alignItems: 'center',
  //     }),
  //   }

  return (
    <>
      <ReactSelect
        options={transformData}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={handleChange}
        value={state.optionSelected}
        noOptionsMessage={() => 'Habilidad no disponible'}
        placeholder={t('choose_the_skills')}
        required
      />

      {errorMaxSkills && (
        <span className="text-danger">solo puedes seleccionar 12</span>
      )}
    </>
  )
}

export default SelectSkills
