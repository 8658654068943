import { useTranslation } from 'react-i18next'
import CheckSmallIcon from '../../icons/CheckSmallIcon'

const ActiveBadge = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <div
      className="badge rounded-pill bg-bg-success text-on-bg-success fw-bolder d-flex align-items-center gap-1 max-w-max font-small-3"
      style={{
        maxWidth: 'max-content',
      }}
      {...rest}
    >
      <CheckSmallIcon />
      <span>{t('active')}</span>
    </div>
  )
}

export default ActiveBadge
