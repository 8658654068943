import Skeleton from 'react-loading-skeleton'

export const ListOfJobOffersSkeleton = ({
  count = 3,
  width = '500px',
  height = '222px',
}) => {
  const listOfJobOffers = Array.from({ length: count }, (_, index) => index)

  return listOfJobOffers.map((__, index) => (
    <Skeleton
      key={index}
      className="mt-2"
      style={{
        width,
        height,
      }}
    />
  ))
}
