const ProgressStatusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <mask
        id="mask0_3098_10102"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="13"
      >
        <rect y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3098_10102)">
        <path
          d="M1.5 10.5C1.225 10.5 0.989583 10.4021 0.79375 10.2063C0.597917 10.0104 0.5 9.775 0.5 9.5C0.5 9.225 0.597917 8.98958 0.79375 8.79375C0.989583 8.59792 1.225 8.5 1.5 8.5H1.63125C1.66875 8.5 1.70833 8.50833 1.75 8.525L4.025 6.25C4.00833 6.20833 4 6.16875 4 6.13125V6C4 5.725 4.09792 5.48958 4.29375 5.29375C4.48958 5.09792 4.725 5 5 5C5.275 5 5.51042 5.09792 5.70625 5.29375C5.90208 5.48958 6 5.725 6 6C6 6.01667 5.99167 6.1 5.975 6.25L7.25 7.525C7.29167 7.50833 7.33125 7.5 7.36875 7.5H7.63125C7.66875 7.5 7.70833 7.50833 7.75 7.525L9.525 5.75C9.50833 5.70833 9.5 5.66875 9.5 5.63125V5.5C9.5 5.225 9.59792 4.98958 9.79375 4.79375C9.98958 4.59792 10.225 4.5 10.5 4.5C10.775 4.5 11.0104 4.59792 11.2063 4.79375C11.4021 4.98958 11.5 5.225 11.5 5.5C11.5 5.775 11.4021 6.01042 11.2063 6.20625C11.0104 6.40208 10.775 6.5 10.5 6.5H10.3688C10.3313 6.5 10.2917 6.49167 10.25 6.475L8.475 8.25C8.49167 8.29167 8.5 8.33125 8.5 8.36875V8.5C8.5 8.775 8.40208 9.01042 8.20625 9.20625C8.01042 9.40208 7.775 9.5 7.5 9.5C7.225 9.5 6.98958 9.40208 6.79375 9.20625C6.59792 9.01042 6.5 8.775 6.5 8.5V8.36875C6.5 8.33125 6.50833 8.29167 6.525 8.25L5.25 6.975C5.20833 6.99167 5.16875 7 5.13125 7H5C4.98333 7 4.9 6.99167 4.75 6.975L2.475 9.25C2.49167 9.29167 2.5 9.33125 2.5 9.36875V9.5C2.5 9.775 2.40208 10.0104 2.20625 10.2063C2.01042 10.4021 1.775 10.5 1.5 10.5ZM2 5.4875L1.6875 4.8125L1.0125 4.5L1.6875 4.1875L2 3.5125L2.3125 4.1875L2.9875 4.5L2.3125 4.8125L2 5.4875ZM7.5 5L7.025 3.975L6 3.5L7.025 3.025L7.5 2L7.975 3.025L9 3.5L7.975 3.975L7.5 5Z"
          fill="#208587"
        />
      </g>
    </svg>
  )
}

export default ProgressStatusIcon
