const HouseIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_819_7381"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_819_7381)">
        <path
          d="M11.3332 6.5H12.6665V5.16667H11.3332V6.5ZM11.3332 9.16667H12.6665V7.83333H11.3332V9.16667ZM11.3332 11.8333H12.6665V10.5H11.3332V11.8333ZM11.3332 14.5V13.1667H13.9998V3.83333H7.99984V4.76667L6.6665 3.8V2.5H15.3332V14.5H11.3332ZM0.666504 14.5V7.83333L5.33317 4.5L9.99984 7.83333V14.5H5.99984V11.1667H4.6665V14.5H0.666504ZM1.99984 13.1667H3.33317V9.83333H7.33317V13.1667H8.6665V8.5L5.33317 6.13333L1.99984 8.5V13.1667Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default HouseIcon

export const HouseTwoIcon = ({ width = 24, height = 24, fill = '#00CE83' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_889_13993"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_889_13993)">
        <path
          d="M17 9H19V7H17V9ZM17 13H19V11H17V13ZM17 17H19V15H17V17ZM17 21V19H21V5H12V6.4L10 4.95V3H23V21H17ZM1 21V11L8 6L15 11V21H9V16H7V21H1ZM3 19H5V14H11V19H13V12L8 8.45L3 12V19Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
