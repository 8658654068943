const CashIcon = ({ width = 24, height = 24, fill = '#00CF83' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <mask
      id="a"
      width={width}
      height={height}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 .5h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fill}
        d="M14 13.5a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 11 10.5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 14 7.5c.833 0 1.542.292 2.125.875S17 9.667 17 10.5s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 14 13.5Zm-7 3c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 5 14.5v-8c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 7 4.5h14c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 21 16.5H7Zm2-2h10c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 21 12.5v-4c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 19 6.5H9c0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 7 8.5v4c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412Zm11 6H3c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 1 18.5v-11h2v11h17v2Z"
      />
    </g>
  </svg>
)
export default CashIcon
