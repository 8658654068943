const EditIcon = ({ fill = '#00CF83', width = '24', height = '24' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fill}
        d="M5 23.7c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 3 21.7v-14c0-.55.196-1.021.587-1.413A1.926 1.926 0 0 1 5 5.7h8.925l-2 2H5v14h14v-6.95l2-2v8.95c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 19 23.7H5ZM16.175 6.275l1.425 1.4-6.6 6.6V15.7h1.4l6.625-6.625 1.425 1.4-7.2 7.225H9v-4.25l7.175-7.175Zm4.275 4.2-4.275-4.2 2.5-2.5c.4-.4.88-.6 1.438-.6.558 0 1.029.2 1.412.6l1.4 1.425c.383.383.575.85.575 1.4 0 .55-.192 1.016-.575 1.4l-2.475 2.475Z"
      />
    </g>
  </svg>
)
export default EditIcon
