const FirstIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="144"
    height="129"
    viewBox="0 0 144 129"
    fill="none"
  >
    <path
      d="M36.3853 79.9737L47.299 77.2485C45.7009 87.3701 51.8655 98.1139 57.0018 105.008C59.4527 108.31 58.8749 113.521 55.9767 114.236L48.9121 116.023C40.308 118.167 36.3853 79.9737 36.3853 79.9737Z"
      fill="url(#paint0_linear_13578_373405)"
    />
    <path
      d="M32.0917 81.0638L57.0257 74.818C60.9214 73.8226 64.2664 71.3291 66.3332 67.8802C68.4 64.4312 69.0214 60.3057 68.0622 56.401C67.0668 52.5053 64.5733 49.1603 61.1244 47.0935C57.6754 45.0267 53.5499 44.4053 49.6452 45.3645L24.702 51.6374C20.8017 52.6149 17.4492 55.1012 15.3814 58.5496C13.3136 61.998 12.6997 66.1265 13.6746 70.0274C14.6566 73.9312 17.1475 77.2858 20.6004 79.3549C24.0533 81.4241 28.1861 82.0387 32.0917 81.0638Z"
      fill="url(#paint1_linear_13578_373405)"
    />
    <path
      opacity="0.3"
      d="M32.0917 81.0638L57.0257 74.818C60.9214 73.8226 64.2664 71.3291 66.3332 67.8802C68.4 64.4312 69.0214 60.3057 68.0622 56.401C67.0668 52.5053 64.5733 49.1603 61.1244 47.0935C57.6754 45.0267 53.5499 44.4053 49.6452 45.3645L24.702 51.6374C20.8017 52.6149 17.4492 55.1012 15.3814 58.5496C13.3136 61.998 12.6997 66.1265 13.6746 70.0274C14.6566 73.9312 17.1475 77.2858 20.6004 79.3549C24.0533 81.4241 28.1861 82.0387 32.0917 81.0638Z"
      fill="black"
    />
    <path
      d="M100.955 82.6431L57.9722 84.288C54.2769 85.2173 42.3033 37.4866 45.9986 36.5573L84.7015 17.7674L100.955 82.6431Z"
      fill="url(#paint2_linear_13578_373405)"
    />
    <path
      d="M89.4384 51.0398C93.9148 68.9456 99.0821 83.0979 100.957 82.6424C102.832 82.1868 100.708 67.2479 96.2311 49.3421C91.7546 31.4364 86.5874 17.284 84.7035 17.7667C82.8196 18.2493 84.989 33.1432 89.4384 51.0398Z"
      fill="url(#paint3_linear_13578_373405)"
    />
    <path
      opacity="0.3"
      d="M89.4384 51.0398C93.9148 68.9456 99.0821 83.0979 100.957 82.6424C102.832 82.1868 100.708 67.2479 96.2311 49.3421C91.7546 31.4364 86.5874 17.284 84.7035 17.7667C82.8196 18.2493 84.989 33.1432 89.4384 51.0398Z"
      fill="black"
    />
    <path
      d="M100.121 49.8068C99.031 45.4232 94.4075 42.2953 92.3243 42.8316C90.2412 43.3679 89.4502 47.3292 90.5402 51.7128C91.6302 56.0964 94.2356 59.2088 96.3096 58.6996C97.8661 57.8766 99.0933 56.545 99.7869 54.9268C100.48 53.3086 100.598 51.5015 100.121 49.8068Z"
      fill="url(#paint4_linear_13578_373405)"
    />
    <path
      d="M126.32 9.37877C127.561 8.18309 129.536 8.21942 130.731 9.45994C131.927 10.7005 131.891 12.6754 130.65 13.8711L112.037 31.812C110.796 33.0077 108.821 32.9714 107.625 31.7308C106.43 30.4903 106.466 28.5154 107.707 27.3197L126.32 9.37877Z"
      fill="url(#paint5_linear_13578_373405)"
    />
    <path
      d="M140.17 40.6213C141.879 40.4 143.443 41.6058 143.665 43.3145C143.886 45.0232 142.68 46.5877 140.971 46.809L115.333 50.1287C113.624 50.3499 112.06 49.1441 111.839 47.4355C111.617 45.7268 112.823 44.1623 114.532 43.941L140.17 40.6213Z"
      fill="url(#paint6_linear_13578_373405)"
    />
    <path
      d="M141.231 75.8069C142.796 76.5269 143.481 78.3795 142.761 79.9448C142.041 81.5101 140.189 82.1954 138.623 81.4754L115.136 70.6724C113.571 69.9524 112.886 68.0998 113.606 66.5345C114.326 64.9692 116.178 64.2839 117.744 65.0039L141.231 75.8069Z"
      fill="url(#paint7_linear_13578_373405)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13578_373405"
        x1="47.4411"
        y1="100.519"
        x2="59.0427"
        y2="104.428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13578_373405"
        x1="41.993"
        y1="68.2698"
        x2="61.518"
        y2="74.8478"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_13578_373405"
        x1="76.7154"
        y1="60.4395"
        x2="101.913"
        y2="68.9285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_13578_373405"
        x1="92.8249"
        y1="56.1546"
        x2="106.576"
        y2="60.7875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_13578_373405"
        x1="94.9506"
        y1="52.4628"
        x2="99.4724"
        y2="53.9862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_13578_373405"
        x1="127.133"
        y1="52.4591"
        x2="141.779"
        y2="52.4591"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_13578_373405"
        x1="127.133"
        y1="52.4591"
        x2="141.779"
        y2="52.4591"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_13578_373405"
        x1="127.133"
        y1="52.4591"
        x2="141.779"
        y2="52.4591"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset="1" stopColor="#00FF5E" />
      </linearGradient>
    </defs>
  </svg>
)
export default FirstIcon
