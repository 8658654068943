import { useTranslation } from 'react-i18next'
import useResize from '../../../hooks/useResize'
import { VIEWPORT_LG } from '../../../utils/constants'

const Description = ({ description }) => {
  const { t } = useTranslation()
  const viewportSize = useResize()
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  return (
    <div className="bg-white mt-3 p-4 mb-3 rounded">
      <div className="d-flex justify-content-between align-items-start">
        <span
          className=" fw-bold text-on-bg-primary"
          style={{
            fontSize: isMobile ? '16px' : '22px',
          }}
        >
          {t('description_of_the_position_and_functions_to_be_performed')}
        </span>{' '}
      </div>
      <span
        className="text-on-bg-secondary mt-4"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

export default Description
