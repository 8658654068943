const MoneyIcon = ({ fill = '#004949', width = 16, height = 12 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill={fill}
      d="M9.333 6.667a1.929 1.929 0 0 1-1.417-.584 1.929 1.929 0 0 1-.583-1.416c0-.556.195-1.028.583-1.417a1.929 1.929 0 0 1 1.417-.583c.556 0 1.028.194 1.417.583.389.389.583.861.583 1.417 0 .555-.194 1.027-.583 1.416a1.929 1.929 0 0 1-1.417.584Zm-4.667 2c-.366 0-.68-.13-.941-.392a1.284 1.284 0 0 1-.392-.942V2c0-.367.13-.68.392-.942.26-.26.575-.391.942-.391H14c.367 0 .68.13.941.391.262.261.392.575.392.942v5.333c0 .367-.13.68-.392.942a1.284 1.284 0 0 1-.941.392H4.666ZM6 7.333h6.666c0-.366.131-.68.392-.941C13.32 6.13 13.633 6 14 6V3.333c-.367 0-.68-.13-.942-.391A1.284 1.284 0 0 1 12.666 2H6c0 .367-.13.68-.392.942a1.284 1.284 0 0 1-.941.391V6c.366 0 .68.13.941.392.261.26.392.575.392.941Zm7.333 4H2c-.367 0-.68-.13-.942-.391A1.284 1.284 0 0 1 .667 10V2.667H2V10h11.333v1.333Z"
    />
  </svg>
)
export default MoneyIcon
