import Button from '../../../components/Button'
import { VIEWPORT_LG } from '../../../utils/constants'

export const TYPE_OF_TAB_BAR_BUTTONS = {
  all: 0,
  categories: 1,
  skills: 2,
  english: 3,
  experience: 4,
  sector: 5,
  country: 6,
}

const TabBar = ({ viewportSize, selectedButton, setSelectedButton, t }) => {
  const handleButtonClick = (button) => {
    setSelectedButton(button)
  }
  

  return (
    <div
      className="bg-on-bg-brand p-1 rounded d-flex justify-content-lg-center align-items-lg-center gap-lg-2 gap-0 overflow-auto"
      role="group"
      aria-label="Basic radio toggle button group"
      style={{
        width: '100%',
      }}
    >
      <Button
        type="button"
        name="btnradio"
        id="btnradio2"
        className={`${
          selectedButton === TYPE_OF_TAB_BAR_BUTTONS.categories
            ? 'bg-bg-CTA-primary'
            : 'bg-on-bg-brand'
        } text-on-bg-primary ${
          viewportSize[0] <= VIEWPORT_LG
            ? 'font-extra-small'
            : 'font-extra-small'
        }`}
        onClick={() => handleButtonClick(TYPE_OF_TAB_BAR_BUTTONS.categories)}
      >
        {t('categories')}
      </Button>

      <Button
        type="button"
        name="btnradio"
        id="btnradio2"
        className={`${
          selectedButton === TYPE_OF_TAB_BAR_BUTTONS.skills
            ? 'bg-bg-CTA-primary'
            : 'bg-on-bg-brand'
        } text-on-bg-primary ${
          viewportSize[0] <= VIEWPORT_LG
            ? 'font-extra-small'
            : 'font-extra-small'
        }`}
        onClick={() => handleButtonClick(TYPE_OF_TAB_BAR_BUTTONS.skills)}
      >
        {t('skills')}
      </Button>

      <Button
        type="button"
        name="btnradio"
        id="btnradio2"
        className={`${
          selectedButton === TYPE_OF_TAB_BAR_BUTTONS.english
            ? 'bg-bg-CTA-primary'
            : 'bg-on-bg-brand'
        } text-on-bg-primary ${
          viewportSize[0] <= VIEWPORT_LG
            ? 'font-extra-small'
            : 'font-extra-small'
        }`}
        onClick={() => handleButtonClick(TYPE_OF_TAB_BAR_BUTTONS.english)}
      >
        {t('english')}
      </Button>

      <Button
        type="button"
        name="btnradio"
        id="btnradio3"
        className={`${
          selectedButton === TYPE_OF_TAB_BAR_BUTTONS.experience
            ? 'bg-bg-CTA-primary'
            : 'bg-on-bg-brand'
        } text-on-bg-primary ${
          viewportSize[0] <= VIEWPORT_LG
            ? 'font-extra-small'
            : 'font-extra-small'
        }`}
        onClick={() => handleButtonClick(TYPE_OF_TAB_BAR_BUTTONS.experience)}
      >
        {t('experience')}
      </Button>

      <Button
        type="button"
        name="btnradio"
        id="btnradio3"
        className={`${
          selectedButton === TYPE_OF_TAB_BAR_BUTTONS.sector
            ? 'bg-bg-CTA-primary'
            : 'bg-on-bg-brand'
        } text-on-bg-primary ${
          viewportSize[0] <= VIEWPORT_LG
            ? 'font-extra-small'
            : 'font-extra-small'
        }`}
        onClick={() => handleButtonClick(TYPE_OF_TAB_BAR_BUTTONS.sector)}
      >
        {t('sector')}
      </Button>

      <Button
        type="button"
        name="btnradio"
        id="btnradio3"
        className={`${
          selectedButton === TYPE_OF_TAB_BAR_BUTTONS.country
            ? 'bg-bg-CTA-primary'
            : 'bg-on-bg-brand'
        } text-on-bg-primary ${
          viewportSize[0] <= VIEWPORT_LG
            ? 'font-extra-small'
            : 'font-extra-small'
        }`}
        onClick={() => handleButtonClick(TYPE_OF_TAB_BAR_BUTTONS.country)}
      >
        {t('country')}
      </Button>
    </div>
  )
}

export default TabBar
