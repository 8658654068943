/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import { TAGS } from '../../../services/Tags'
import { useNavigate, useParams } from 'react-router-dom'
import Header from './Header'
import Loader from '../../../components/Loader'
import Requirements from './Requirements'
import GeneralSkills from './GeneralSkills'
import Description from './Description'
import AdditionalBenefits from './AdditionalBenefits'
import {
  getMyOfferTalentDetail,
  getMyOffersManagementDetail,
  getPublicJobOfferDetail,
} from '../../../services/OfferManagement'
import DashboardWrapper from '../../Dashboard/components/DashboardWrapper'
import Divider from '../../../components/Divider'
import { useState } from 'react'
import {
  INVITATION_CODE_ORIGIN,
  IS_ADMIN,
  IS_COMPANY,
  IS_HIRING_ADVISOR,
  IS_RECRUITER,
  IS_TALENT,
  OUT_OF_THE_APP,
  TYPE_OF_USER,
  VIEWPORT_LG,
} from '../../../utils/constants'
import LocationIcon from '../../../icons/LocationIcon'
import BriefcaseOutlineIcon from '../../../icons/BriefcaseOutlineIcon'
import CalendarOutlineIcon from '../../../icons/CalendarOutlineIcon'
import MagnifyingGlass from '../../../icons/MagnifyingGlass'
import RocketIcon from '../../../icons/RocketIcon'
import StarTwoIcon from '../../../icons/StarTwoIcon'
import useGoToTop from '../../../hooks/useGoToTop'
import { getProfileUser } from '../../../services/MyProfile'
import { MY_RESUMEN_TAGS } from '../../../services/Tags/myResumen'
//import DuplicateJobOfferModal from './DuplicateJobOfferModal'
import DesactivateJobOfferModal from './DesactivateJobOfferModal'
import Circle from '../../../components/Circle'
import StoreIcon from '../../../icons/StoreIcon'
import useResize from '../../../hooks/useResize'
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon'
import InnerBanner from '../../../components/InnerBanner'
import { companyDetail } from '../../../services/HiringAdvisor'
import { typeUser } from '../../../localStorage'
import { PRIVATE_ROUTES } from '../../../Routes/routes'
import { useTranslation } from 'react-i18next'
import FirstIcon from '../../../icons/downloadJobOffer/FirstIcon'
import HouseAndBuildingIcon from '../../../icons/HouseAndBuildingIcon'
import MoneyIcon from '../../../icons/fastoffer/MoneyIcon'
import HutritIconThree from '../../../icons/HutritIconThree'
import Button from '../../../components/Button'
import ShareIcon from '../../../icons/ShareIcon'
import DownLoadIcon from '../../../icons/DownLoadIcon'
import { copyClipboard } from '../../../utils'
import Toast from '../../../components/Toast/Toast'
import ArrowRigthIcon from '../../../icons/ArrowRigthIcon'
import { useVacanciesStore } from '../../../store'
import { JobOfferDetailSkeleton } from '../../../skeleton/Global/JobOffers/JobOfferDetailSkeleton'
import ArrowLeftIconTwo from '../../../icons/ArrowLeftIconTwo'
import ArrowLeftBentIcon from '../../../icons/ArrowLeftBentIcon'
import ArrowRigthBentIcon from '../../../icons/ArrowRigthBentIcon'

const JobOfferDetailPublic = ({ isDownloadOfferView = false }) => {
  const viewportSize = useResize()
  const navigation = useNavigate()
  const { offerManagementId } = useParams()
  const [active, setActive] = useState(1)
  const { t } = useTranslation()
  const { id: selectedId, setId: setSelectedId } = useVacanciesStore()
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  const {
    data: offerManagementDetailData = [],
    isInitialLoading,
    //isFetching: isFetchingOfferManagementDetail,
  } = useQuery(['public-vacancie-detail', offerManagementId, selectedId], () =>
    getPublicJobOfferDetail(selectedId ?? offerManagementId)
  )

  const {
    offert_langs = {},
    starting_amount,
    final_amount,
    range_year = {},
    offert_skills = [],
    description = '',
    it_is_value = '',
    company = {},
    companies_simple = {},
    date_create = '',
    coin = {},
    active: offer_active,
    modality_offerts = [],
    address = {},
    date_estimated_hiring = '',
    on: offerState = true,
    process_id = 0,
    send = false,
    visible = false,
    category_job = {},
    company_sector = null,
  } = offerManagementDetailData ?? {}

  useGoToTop()

  return (
    <div className={`${isMobile ? isInitialLoading ? 'd-none' : 'w-100' : ''}`}>
      <div className="d-lg-none d-flex align-items-center justify-content-between p-3">
        <div className="d-flex" onClick={() => setSelectedId(null)}>
          <ArrowLeftIconTwo />
          <span className="text-on-bg-primary">Atrás</span>
        </div>
        <div className="d-flex align-items-center gap-1 d-none">
          <div className="d-flex alig-items-center justify-content-center p-2 rounded border border-on-bg-primary">
            <ArrowLeftBentIcon />
          </div>
          <div className="d-flex alig-items-center justify-content-center p-2 rounded border border-on-bg-primary">
            <ArrowRigthBentIcon />
          </div>
        </div>
      </div>
      {isInitialLoading ? (
        <JobOfferDetailSkeleton width={isMobile ? '100%' : '900px'} />
      ) : (
        <div
          className="d-flex align-items-start gap-5"
          style={{
            height: isMobile ? 'auto' : '65vh',
            overflow: isMobile ? '' : 'auto',
          }}
        >
          <div
            className="border-bg-blue bg-bg-blue-opacity p-4 rounded"
            style={{
              width: isMobile ? '100%' : '900px',
            }}
          >
            <Header
              position={category_job?.name}
              company={company}
              companies_simple={companies_simple}
              starting_amount={starting_amount}
              final_amount={final_amount}
              offerManagementId={offerManagementId}
              accept={false}
              expired={false}
              isCompany={IS_COMPANY}
              isTalent={IS_TALENT}
              company_sector_by_talent={{}}
              company_sector={company_sector}
              date_create={date_create}
              offer_status_id={0}
              coin={coin}
              offer_date={''}
              active={offer_active}
              modality_offerts={modality_offerts || []}
              address={address}
              date_estimated_hiring={date_estimated_hiring}
              offerState={offerState}
              process_id={process_id}
              send={send}
              visible={visible}
            />

            <div className="d-flex flex-lg-row flex-column align-items-start justify-content-center gap-2">
              <Requirements
                offert_lang={offert_langs}
                range_year={range_year}
                isCompany={IS_COMPANY}
                starting_amount={starting_amount}
                final_amount={final_amount}
                coin={coin}
              />
              <GeneralSkills skills={offert_skills} isCompany={IS_COMPANY} />
            </div>

            <AdditionalBenefits
              it_is_value={it_is_value}
              isCompany={IS_COMPANY}
            />

            <Description description={description} isCompany={IS_COMPANY} />
          </div>
        </div>
      )}
    </div>
  )
}

export default JobOfferDetailPublic
