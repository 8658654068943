const HutritGreenIcon = ({
  width = '104',
  height = '29',
  fill = '#00CF83',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 104 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2247 28.7363H15.9718V20.551C12.7359 21.9654 9.53862 21.9734 6.29825 20.5578V28.7182H0.0192588V8.37039H6.27785V8.96203C6.27785 11.0214 6.28805 13.0796 6.26992 15.139C6.26992 15.5278 6.36739 15.7386 6.74481 15.8984C9.63156 17.1281 13.4885 17.0318 15.9706 15.6422V8.37153H22.2247V28.7363Z"
        fill={fill}
      />
      <path
        d="M40.6219 8.35451H46.8635V28.7204H40.6298V26.023C39.5134 27.4068 38.2316 28.2512 36.6856 28.6774C32.9715 29.6974 29.3016 28.2455 27.6015 25.072C26.903 23.7477 26.5388 22.2726 26.5406 20.7754C26.5032 16.8663 26.5282 12.955 26.5282 9.04701V8.38058H32.7618V9.00054C32.7618 12.4189 32.7562 15.8372 32.7618 19.2555C32.7618 20.3027 32.9001 21.3285 33.5359 22.2125C34.4721 23.5114 36.2039 24.0418 37.8961 23.559C39.4398 23.1192 40.4598 21.843 40.555 20.1395C40.6162 19.0288 40.6106 17.9135 40.6128 16.8005C40.6208 14.1937 40.6128 11.5869 40.6128 8.98014L40.6219 8.35451Z"
        fill={fill}
      />
      <path
        d="M52.3536 13.5489H49.893V8.35225H52.3378V3.3778H58.5873V8.32052H62.7049V13.5228H58.6065V21.3205C58.6065 22.9764 59.035 23.4105 60.6693 23.4105H62.731V28.7624C60.5503 28.6898 58.3561 28.9981 56.2378 28.2433C53.9551 27.4318 52.7424 25.7555 52.4783 23.4003C52.3536 22.276 52.3672 21.1426 52.3536 20.0092C52.3412 17.8795 52.3536 15.7476 52.3536 13.5489Z"
        fill={fill}
      />
      <path
        d="M104 28.7635C102 28.6898 99.9969 28.9483 98.0338 28.4042C95.4996 27.7027 93.9921 25.9595 93.7337 23.3244C93.6295 22.2567 93.6204 21.1766 93.6204 20.1021C93.6011 17.9487 93.6102 15.7952 93.6057 13.6418C93.6057 13.6271 93.5841 13.6135 93.5331 13.5511H91.1405V8.37153H93.5773V3.3846H99.8801V8.31826H103.952V13.5319H99.9005V14.0986C99.9005 16.592 99.9005 19.0855 99.9005 21.5789C99.9005 22.9288 100.388 23.4037 101.756 23.406H104V28.7635Z"
        fill={fill}
      />
      <path
        d="M72.4736 28.7363H66.2309V8.36925H72.477V11.638C74.1239 9.40404 76.1334 8.12444 78.8512 8.10517V14.7774C78.076 14.7774 77.3246 14.7661 76.5743 14.7774C76.2543 14.7886 75.9358 14.8265 75.6222 14.8908C73.624 15.2818 72.6312 16.3823 72.528 18.4224C72.4634 19.7587 72.477 21.1006 72.4714 22.4403C72.4634 24.3285 72.4714 26.2168 72.4714 28.1073L72.4736 28.7363Z"
        fill={fill}
      />
      <path d="M88.0963 8.37946V28.7204H81.8627V8.37946H88.0963Z" fill={fill} />
      <path
        d="M81.857 6.50257V0.268911H88.068V6.50823L81.857 6.50257Z"
        fill={fill}
      />
      <path
        d="M19.1045 6.50709C18.485 6.50395 17.8804 6.31764 17.3666 5.97161C16.8528 5.62559 16.4529 5.13531 16.2172 4.56249C15.9814 3.98967 15.9204 3.35992 16.0417 2.75249C16.1631 2.14506 16.4615 1.58711 16.8992 1.14887C17.337 0.710631 17.8946 0.411683 18.5019 0.289659C19.1092 0.167634 19.739 0.227986 20.3121 0.463118C20.8852 0.698249 21.3759 1.09765 21.7225 1.61105C22.069 2.12445 22.256 2.72891 22.2598 3.34833C22.2634 3.76386 22.1843 4.17597 22.0271 4.56062C21.8698 4.94527 21.6376 5.29478 21.3439 5.58878C21.0502 5.88277 20.701 6.11538 20.3165 6.27304C19.932 6.4307 19.52 6.51027 19.1045 6.50709Z"
        fill={fill}
      />
      <path
        d="M3.15194 6.50823C2.52983 6.51047 1.92106 6.3279 1.40285 5.98366C0.884645 5.63943 0.480347 5.14905 0.241237 4.57471C0.00212632 4.00038 -0.061023 3.36796 0.0597974 2.75769C0.180618 2.14741 0.479964 1.58677 0.91987 1.14686C1.35978 0.706954 1.92042 0.407607 2.5307 0.286787C3.14098 0.165967 3.77339 0.229117 4.34772 0.468227C4.92206 0.707338 5.41244 1.11163 5.75667 1.62984C6.10091 2.14805 6.28348 2.75682 6.28124 3.37893C6.27737 4.20768 5.94643 5.00138 5.36041 5.5874C4.77439 6.17342 3.98069 6.50435 3.15194 6.50823Z"
        fill={fill}
      />
    </svg>
  )
}

export default HutritGreenIcon
