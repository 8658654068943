const DownLoadIcon = ({ fill = '#004949', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fill}
        d="M4 13.333c-.367 0-.68-.13-.942-.391A1.284 1.284 0 0 1 2.667 12v-2H4v2h8v-2h1.333v2c0 .367-.13.68-.391.942a1.284 1.284 0 0 1-.942.391H4Zm4-2.666L4.667 7.333l.933-.966L7.333 8.1V2.667h1.334V8.1L10.4 6.367l.933.966L8 10.667Z"
      />
    </g>
  </svg>
)
export default DownLoadIcon
