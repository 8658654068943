const BriefcaseClockIcon = ({ width = 32, height = 32, fill = '#004949' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/work_history">
        <mask
          id="mask0_9174_6840"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect id="Bounding box" width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9174_6840)">
          <path
            id="icon"
            d="M5.33341 28C4.60008 28 3.9723 27.7388 3.45008 27.2166C2.92786 26.6944 2.66675 26.0666 2.66675 25.3333V10.6666C2.66675 9.93329 2.92786 9.30552 3.45008 8.78329C3.9723 8.26107 4.60008 7.99996 5.33341 7.99996H10.6667V5.33329C10.6667 4.59996 10.9279 3.97218 11.4501 3.44996C11.9723 2.92774 12.6001 2.66663 13.3334 2.66663H18.6667C19.4001 2.66663 20.0279 2.92774 20.5501 3.44996C21.0723 3.97218 21.3334 4.59996 21.3334 5.33329V7.99996H26.6667C27.4001 7.99996 28.0279 8.26107 28.5501 8.78329C29.0723 9.30552 29.3334 9.93329 29.3334 10.6666V16.3666C28.9334 16.0777 28.5112 15.8277 28.0667 15.6166C27.6223 15.4055 27.1556 15.2222 26.6667 15.0666V10.6666H5.33341V25.3333H14.7667C14.8334 25.8 14.9334 26.2555 15.0667 26.7C15.2001 27.1444 15.3667 27.5777 15.5667 28H5.33341ZM13.3334 7.99996H18.6667V5.33329H13.3334V7.99996ZM24.0001 30.6666C22.1556 30.6666 20.5834 30.0166 19.2834 28.7166C17.9834 27.4166 17.3334 25.8444 17.3334 24C17.3334 22.1555 17.9834 20.5833 19.2834 19.2833C20.5834 17.9833 22.1556 17.3333 24.0001 17.3333C25.8445 17.3333 27.4167 17.9833 28.7167 19.2833C30.0168 20.5833 30.6667 22.1555 30.6667 24C30.6667 25.8444 30.0168 27.4166 28.7167 28.7166C27.4167 30.0166 25.8445 30.6666 24.0001 30.6666ZM24.6667 23.7333V20H23.3334V24.2666L26.2001 27.1333L27.1334 26.2L24.6667 23.7333Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export default BriefcaseClockIcon
