import { t } from 'i18next'
import Badge from '../../../components/Badge/Badge'
import Divider from '../../../components/Divider'
import Tooltip from '../../../components/Tooltip'
import ClockIcon from '../../../icons/ClockIcon'
import MoneyIcon from '../../../icons/fastoffer/MoneyIcon'
import HouseAndBuildingIcon from '../../../icons/HouseAndBuildingIcon'
import { useVacanciesStore } from '../../../store'
import { formatCurrency } from '../../../utils'
import { NOT_AVAILABLE } from '../../../utils/constants'

const VacanciesJobOfferCard = ({
  id,
  category_job = {},
  offert_skills = [],
  modality_offerts = [],
  address = {},
  // range_year = {},
  coin = {},
  starting_amount = 0,
  final_amount = 0,
  company_sector,
}) => {
  const { id: selectedId } = useVacanciesStore()
  const totalElementsToShow = 2
  const showSkills = offert_skills?.length > 3
  const isSelectedId = Number(selectedId) === id

  const elementsToShow = offert_skills?.slice(0, totalElementsToShow)

  return (
    <div
      id={`vacancie-${id}`}
      className={`w-100 border ${
        isSelectedId ? 'border-bg-CTA-primary shadow' : 'border-on-bg-Tertiary'
      }`}
      style={{
        borderRadius: '8px',
        padding: '20px',
        height: '148px',
        background: isSelectedId ? '#f5fdfa' : '#f8f8fa',
      }}
    >
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex flex-column align-items-start">
          <span>{category_job?.name}</span>
          <span
            className="text-on-bg-primary"
            style={{
              fontSize: '10px',
            }}
          >
            {company_sector}
          </span>
        </div>
      </div>

      <Divider className="w-100 bg-on-bg-primary mt-2 mb-2" />
      <div
        className="d-flex align-items-center gap-1"
        style={{
          fontSize: '12px',
        }}
      >
        <div className="d-flex align-items-center gap-1">
          <MoneyIcon fill="#004949" width={16} height={16} />
          <>
            <span className="text-on-bg-primary">
              {formatCurrency({
                amount: String(starting_amount),
                symbol: coin?.symbol,
                short_name: final_amount === 0 ? coin?.short_name : '',
              })}
            </span>
            {final_amount !== 0 && (
              <span className="text-on-bg-primary">
                {' '}
                -{' '}
                {formatCurrency({
                  amount: String(final_amount),
                  symbol: coin?.symbol,
                  short_name: coin?.short_name,
                })}{' '}
              </span>
            )}
          </>
        </div>
        <div className="d-flex align-items-center gap-1">
          <HouseAndBuildingIcon fill="#004949" width={16} height={16} />
          {modality_offerts?.[0]?.modality?.id === 3 ? (
            <div className="d-flex align-items-center gap-1">
              <span className="font-small-2 inline-text text-on-bg-primary">
                ({modality_offerts?.[0]?.modality?.name})
              </span>

              <span className="font-small-2 inline-text d-none">
                {address?.city?.city_name}
              </span>

              <Tooltip
                text={address?.country?.country_name}
                style={{
                  zIndex: '3000',
                }}
              >
                <img
                  className="rounded-circle"
                  width={12}
                  height={12}
                  src={address?.country?.flag}
                />
              </Tooltip>
            </div>
          ) : (
            <span className="font-small-2 inline-text text-on-bg-primary">
              {t('remote')}
            </span>
          )}
        </div>
        <div className="d-flex align-items-center gap-1 d-none">
          <ClockIcon fill="#004949" width={16} height={16} />
          <span className="text-on-bg-primary">hace 2 días</span>
        </div>
      </div>
      <div className="position-relative  w-100">
        <div className="d-flex align-items-center mt-2 mb-3 w-100 overflow-auto">
          {elementsToShow?.map(({ skills_categori, id }) => (
            <Badge
              key={id}
              className="border border-on-bg-CTA-primary-selected text-on-bg-CTA-primary-selected rounded-pill me-2"
              style={{
                width: 'max-content',
                height: 'max-content',
              }}
            >
              {skills_categori?.skill?.name || NOT_AVAILABLE}
            </Badge>
          ))}
          {showSkills && (
            <Badge
              className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2"
              style={{
                width: 'max-content',
                height: 'max-content',
              }}
            >
              {`+${offert_skills?.length - 3}`}
            </Badge>
          )}
        </div>
      </div>
    </div>
  )
}

export default VacanciesJobOfferCard
