import { useTranslation } from 'react-i18next'
import DesactiveUserIcon from '../../icons/DesactiveUserIcon'

const DesactiveBadge = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <div
      className="badge rounded-pill bg-disable-secondary text-on-bg-secondary fw-normal d-flex align-items-center gap-1 max-w-max"
      style={{
        maxWidth: 'max-content',
      }}
      {...rest}
    >
      <DesactiveUserIcon />
      <span>{t('disabled')}</span>
    </div>
  )
}

export default DesactiveBadge
