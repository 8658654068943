import useResize from '../../../hooks/useResize'
import { HouseTwoIcon } from '../../../icons/HouseIcon'
import {
  IS_ADMIN,
  IS_COMPANY,
  IS_HIRING_ADVISOR,
  IS_TALENT,
  OUT_OF_THE_APP,
  VIEWPORT_LG,
} from '../../../utils/constants'
import CashIcon from '../../../icons/CashIcon'
import { formatCurrency } from '../../../utils'
import Button from '../../../components/Button'
import Swal from 'sweetalert2'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { TAGS } from '../../../services/Tags'

import Loader from '../../../components/Loader'
import {
  acceptJobOffer,
  declineJobOffer,
  listOfReasonForDeclineJobOffer,
} from '../../../services/OfferManagement'
import EditIcon from '../../../icons/EditIcon'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SearchUserExtraSmallIcon from '../../../icons/SearchUserExtraSmallIcon'
import CopyExtraSmallIcon from '../../../icons/CopyExtraSmallIcon'
import EyeDesactiveExtraSmallIcon from '../../../icons/EyeDesactiveExtraSmallIcon'
import { globalBootstrap } from '../../..'
import { useForm } from 'react-hook-form'
import DeclineJobOfferModal from './DeclineJobOfferModal'
import StoreIcon from '../../../icons/StoreIcon'
import Circle from '../../../components/Circle'
import dayjs from 'dayjs'
import XDangerIcon from '../../../icons/XDangerIcon'
import ProgressStatusIcon from '../../../icons/ProgressStatusIcon'
import EarringStatusIcon from '../../../icons/EarringStatusIcon'
import GlittersIcon from '../../../icons/fastoffer/GlittersIcon'
import Tooltip from '../../../components/Tooltip'
import ClockJobOfferIcon from '../../../icons/ClockJobOfferIcon'
import { useTranslation } from 'react-i18next'
// import BriefcaseClockIcon from '../../../icons/BriefcaseClockIcon'
// import QuestionIcon from '../../../icons/Process/QuestionIcon'
import utc from 'dayjs/plugin/utc'
import ActiveBadge from '../../../components/Badge/ActiveBadge'
import DesactiveBadge from '../../../components/Badge/DesactiveBadge'

//TODO: enable when update duplicate offer FIGMA.
//import WorkingOnItModal from '../../../components/Modals/WorkingOnItModal'

dayjs.extend(utc)

const initialValues = {
  idOffer: '',
  idReason: '',
}

const Header = ({
  company = {},
  companies_simple = {},
  starting_amount,
  final_amount,
  position,
  offerManagementId,
  accept,
  expired,
  company_sector,
  company_sector_by_talent,
  offer_status_id,
  name = '',
  coin,
  offer_date,
  active,
  modality_offerts = [],
  address = {},
  //date_estimated_hiring = '',
  visible = false,
  process_id = 0,
  send = false,
  isDownloadOfferView = false,
}) => {
  const viewportSize = useResize()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm(initialValues)

  const offerDate = dayjs(offer_date)?.format('DD-MM-YYYY')
  // const estimated_hiring_date = dayjs
  //   .utc(date_estimated_hiring)
  //   .format('DD-MM-YYYY')
  const today = dayjs().format('DD-MM-YYYY')

  const {
    data: listOfReasonForDeclineJobOfferData = [],
    isloading: isloadingListOfReasonForDeclineJobOffer,
  } = useQuery(
    [TAGS.reason_for_decline_job_offer],
    listOfReasonForDeclineJobOffer
  )

  const { mutate: declineJobOfferMutate, isLoading: isLoadingDeclineJobOffer } =
    useMutation([TAGS.decline_job_offer], declineJobOffer, {
      onSuccess: ({ status }) => {
        if (status === 200) {
          queryClient.invalidateQueries(TAGS.decline_job_offer)
          SkeletonToast.fire({
            icon: 'success',
            title: `La oferta para ${position} fue rechazada con éxito`,
          })
        }
      },
    })

  const { name: companyName = '', logo } = company || {}
  const emptyLogo = logo === undefined || logo === null

  const SkeletonToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  const {
    mutate: acceptJobOfferMutate,
    isLoading: isLoadingAcceptJobOfferMutate,
  } = useMutation([TAGS.accept_job_offer], acceptJobOffer, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        queryClient.invalidateQueries(TAGS.accept_job_offer)
        SkeletonToast.fire({
          icon: 'success',
          title: `La oferta para ${position} fue aceptada con éxito`,
        })
      }
    },
  })

  const handleAcceptJobOffer = () => {
    acceptJobOfferMutate({
      idOffer: offerManagementId,
    })
  }

  const onSubmit = (data) => {
    declineJobOfferMutate({
      idOffer: offerManagementId,
      idReason: data?.idReason?.[0],
    })
  }

  const handleOnChange = (evt) => {
    const checkboxValue = evt.target.value
    setValue('idReason', checkboxValue)
  }

  const handleOpenDeclineJobOfferModal = () => {
    DeclineJobOfferModal({
      handleOnChange,
      onSubmit,
      handleSubmit,
      register,
      listOfReasonForDeclineJobOfferData,
      isloadingListOfReasonForDeclineJobOffer,
      isLoadingDeclineJobOffer,
      errors,
    })
  }

  const handleOpenDesactivateOJobOfferConfirmModal = () => {
    const modal = globalBootstrap.Modal.getOrCreateInstance(
      '#desactivate-job-offer-modal'
    )
    modal.show()
  }

  const firstDividerDesktop = (
    <div className="d-flex align-items-start justify-content-between">
      <div className="d-flex align-items-center gap-2">
        {!OUT_OF_THE_APP && !isDownloadOfferView && emptyLogo ? (
          <Circle className="bg-bg-tertiary p-5">
            <StoreIcon />
          </Circle>
        ) : (
          !isDownloadOfferView &&
          !OUT_OF_THE_APP && (
            <img
              src={logo}
              width={64}
              height={64}
              className="rounded-pill object-fit-contain"
            />
          )
        )}
        <div className="d-flex flex-column align-items-start">
          <h1 className="fs-lg-1 fs-2 fw-bold text-wrap text-bg-blue">
            {position}
          </h1>
          {!isDownloadOfferView && (
            <div className="d-flex align-items-center gap-3">
              <span
                className={`${
                  viewportSize <= VIEWPORT_LG ? 'font-extra-small' : ''
                } text-on-bg-primary`}
              >{`${
                IS_TALENT || IS_ADMIN
                  ? `${name || companyName || companies_simple?.name} |`
                  : ''
              } ${
                IS_TALENT || IS_ADMIN
                  ? company_sector_by_talent?.name
                  : company_sector?.name
                  ? company_sector?.name ?? ''
                  : company_sector ?? ''
              }`}</span>
              {!IS_HIRING_ADVISOR && !IS_COMPANY && !OUT_OF_THE_APP && (
                <div className="d-flex align-items-center gap-2 overflow-auto">
                  {offerDate === today &&
                    !accept &&
                    offer_status_id !== 2 &&
                    active && (
                      <span className="badge bg-bg-Success text-on-bg-Success rounded-pill p-2 d-flex align-items-center gap-1">
                        <GlittersIcon />{' '}
                        <span className="d-flex">{t('new')}</span>
                      </span>
                    )}
                  {today > offerDate &&
                    offer_status_id !== 2 &&
                    !accept &&
                    active && (
                      <span className="badge bg-bg-warning  text-on-bg-warning rounded-pill p-2 d-flex align-items-center gap-1">
                        <EarringStatusIcon />{' '}
                        <span className="d-flex">{t('earring')}</span>
                      </span>
                    )}
                  {accept && active && (
                    <span className="badge bg-bg-CTA-primary-selected  text-on-bg-CTA-primary-selected rounded-pill p-2 d-flex align-items-center gap-1">
                      <ProgressStatusIcon />{' '}
                      <span className="d-flex">{t('in_progress')}</span>
                    </span>
                  )}
                  {offer_status_id === 2 && active && (
                    <span className="badge bg-bg-danger  text-on-bg-danger rounded-pill p-2 d-flex align-items-center gap-1">
                      <XDangerIcon />{' '}
                      <span className="d-flex">{t('rejectedTwo')}</span>
                    </span>
                  )}
                  {!active && (
                    <span className="badge bg-bg-danger  text-on-bg-danger rounded-pill p-2 d-flex align-items-center gap-1">
                      <XDangerIcon /> <span className="d-flex">Finalizado</span>
                    </span>
                  )}
                </div>
              )}
              {!visible && !OUT_OF_THE_APP && (
                <DesactiveBadge title="Esta oferta está desactivada" />
              )}
              {visible && <ActiveBadge title="Esta oferta está activada" />}
            </div>
          )}
        </div>
      </div>
      {IS_TALENT && (
        <div className="d-flex justify-content-end align-items-center gap-3">
          <Button
            className="btn-on-bg-primary bg-bg-blue-opacity text-on-bg-danger border border-on-bg-danger"
            disabled={
              isLoadingAcceptJobOfferMutate ||
              accept ||
              expired ||
              offer_status_id === 2
            }
            onClick={handleOpenDeclineJobOfferModal}
          >
            {isLoadingAcceptJobOfferMutate ? (
              <Loader />
            ) : (
              <span
                className={`${
                  viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
                } inline-text`}
              >
                {t('decline')}
              </span>
            )}
          </Button>
          <Button
            onClick={handleAcceptJobOffer}
            disabled={
              isLoadingAcceptJobOfferMutate ||
              accept ||
              expired ||
              offer_status_id === 2
            }
            className="btn-bg-CTA-primary text-on-bg-primary"
          >
            {isLoadingAcceptJobOfferMutate ? (
              <Loader />
            ) : (
              <span
                className={`${
                  viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
                } inline-text`}
              >
                {t('accept_now')}
              </span>
            )}
          </Button>
        </div>
      )}
      {IS_COMPANY && (
        <div className="d-flex justify-content-end align-items-center gap-3">
          <div className=" cursor-pointer d-flex justify-content-center align-items-center gap-1">
            <div className="dropdown">
              <a
                className="dropdown-toggle text-on-bg-primary text-decoration-none"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t('bid_options')}
              </a>
              <ul
                className="dropdown-menu font-extra-small"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to={`/process/${process_id}/${offerManagementId}`}
                  >
                    <div className="d-flex justify-content-start align-items-center gap-1">
                      <SearchUserExtraSmallIcon />
                      <span>{t('see_candidates')}</span>
                    </div>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => WorkingOnItModal()}
                  >
                    <div className="d-flex justify-content-start align-items-center gap-1">
                      <CopyExtraSmallIcon />
                      <span>{t('duplicate_offer')}</span>
                    </div>
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={handleOpenDesactivateOJobOfferConfirmModal}
                  >
                    <div className="d-flex justify-content-start align-items-center gap-1">
                      <EyeDesactiveExtraSmallIcon />
                      <span>{t('deactivate_offer')}</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {!send && (
            <div
              className="cursor-pointer"
              title={t('edit_offer')}
              onClick={() => {
                if (send) return

                navigate(`/edit-job-offer/${params?.offerManagementId}`)
              }}
            >
              <EditIcon />
            </div>
          )}
        </div>
      )}
    </div>
  )

  const firstDividerMobile = (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-start mb-2 w-100">
        {IS_COMPANY && (
          <div className="d-flex justify-content-end align-items-center gap-3 mb-2">
            <div className="cursor-pointer d-flex justify-content-center align-items-center gap-1">
              <div className="dropdown">
                <a
                  className="dropdown-toggle text-on-bg-secondary text-decoration-none"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t('bid_options')}
                </a>
                <ul
                  className="dropdown-menu font-extra-small"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to={`/process/${process_id}/${offerManagementId}`}
                    >
                      <div className="d-flex justify-content-start align-items-center gap-1">
                        <SearchUserExtraSmallIcon />
                        <span>{t('see_candidates')}</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-none"
                      to="#"
                      onClick={() => {
                        const modal = globalBootstrap.Modal.getOrCreateInstance(
                          '#duplicate-job-offer-modal'
                        )
                        modal.show()
                      }}
                    >
                      <div className="d-flex justify-content-start align-items-center gap-1">
                        <CopyExtraSmallIcon />
                        <span>{t('duplicate_offer')}</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={handleOpenDesactivateOJobOfferConfirmModal}
                    >
                      <div className="d-flex justify-content-start align-items-center gap-1">
                        <EyeDesactiveExtraSmallIcon />
                        <span>{t('deactivate_offer')}</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center gap-3 w-100">
          <h1 className="fs-lg-1 fs-2 fw-bold text-wrap text-bg-blue">
            {position}
          </h1>
          <div
            className={`${
              OUT_OF_THE_APP ? 'd-none' : ''
            } d-lg-flex align-items-center gap-2 overflow-auto`}
          >
            {offerDate === today &&
              !accept &&
              offer_status_id !== 2 &&
              active && (
                <span className="badge bg-bg-Success text-on-bg-Success rounded-pill p-2 d-flex align-items-center gap-1">
                  <GlittersIcon /> <span className="d-flex">{t('new')}</span>
                </span>
              )}
            {today > offerDate &&
              offer_status_id !== 2 &&
              !accept &&
              active && (
                <span className="badge bg-bg-warning  text-on-bg-warning rounded-pill p-2 d-flex align-items-center gap-1">
                  <EarringStatusIcon />{' '}
                  <span className="d-flex">{t('earring')}</span>
                </span>
              )}
            {accept && active && (
              <span className="badge bg-bg-CTA-primary-selected  text-on-bg-CTA-primary-selected rounded-pill p-2 d-flex align-items-center gap-1">
                <ProgressStatusIcon />{' '}
                <span className="d-flex">{t('in_progress')}</span>
              </span>
            )}
            {offer_status_id === 2 && active && (
              <span className="badge bg-bg-danger  text-on-bg-danger rounded-pill p-2 d-flex align-items-center gap-1">
                <XDangerIcon />{' '}
                <span className="d-flex">{t('rejectedTwo')}</span>
              </span>
            )}
            {!active && (
              <span className="badge bg-bg-danger  text-on-bg-danger rounded-pill p-2 d-flex align-items-center gap-1">
                <XDangerIcon /> <span className="d-flex">Finalizado</span>
              </span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <span
            className={`${
              isMobile ? 'font-extra-small text-center' : ''
            } text-bg-tertiary`}
          >{`${
            (IS_TALENT || IS_ADMIN) &&
            `${name || companyName || companies_simple?.name} |`
          }  ${
            company_sector_by_talent?.name ||
            company_sector?.name ||
            companies_simple?.company_sector?.name ||
            'No disponible'
          }`}</span>
        </div>
      </div>
    </div>
  )

  return (
    <div
      id="job-offer-detail-header"
      className="d-flex flex-column p-2 p-lg-3 rounded-top"
    >
      <div>
        {viewportSize[0] <= VIEWPORT_LG
          ? firstDividerMobile
          : firstDividerDesktop}

        <div className="bg-white rounded shadow-sm text-on-bg-primary d-flex flex-lg-row flex-column justify-content-center align-items-center gap-1 gap-lg-5 mt-4 mb-3 p-3">
          <div
            className={`${
              isMobile ? 'justify-content-between px-2 w-100 mb-2' : 'gap-5'
            } d-flex align-items-center`}
          >
            <div
              className={`${
                viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
              } d-flex align-items-center gap-1 inline-text`}
            >
              <CashIcon fill="#407BFF" />
              <div className="d-flex flex-column align-items-start">
                <span
                  className={`${
                    viewportSize[0] <= VIEWPORT_LG
                      ? 'font-extra-small'
                      : 'font-small-2'
                  } inline-text text-on-bg-secondary`}
                >
                  {t('annual_gross')}
                </span>
                <div className="fw-bolder">
                  <span>
                    {formatCurrency({
                      amount: String(starting_amount),
                      symbol: coin?.symbol,
                      short_name: final_amount === 0 ? coin?.short_name : '',
                    })}
                  </span>
                  {final_amount !== 0 && (
                    <span>
                      {' '}
                      -{' '}
                      {formatCurrency({
                        amount: String(final_amount),
                        symbol: coin?.symbol,
                        short_name: coin?.short_name,
                      })}{' '}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-1">
              {/* {ASK UX TEAM FOR THIS USE CASE} */}
              {modality_offerts?.[0]?.modality?.id === 3 ||
              modality_offerts?.[0]?.modality?.id === 1 ? (
                <>
                  <Tooltip position="top" text={address?.country?.country_name}>
                    <img
                      className="rounded-circle"
                      width={24}
                      height={24}
                      src={address?.country?.flag}
                    />
                  </Tooltip>
                  <div className="d-flex flex-column align-items-start">
                    <span className="text-on-bg-secondary font-small-2">
                      {t('modality')} ({modality_offerts?.[0]?.modality?.name})
                    </span>
                    <span
                      className={`${
                        viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
                      } text-on-bg-primary`}
                    >
                      {' '}
                      {address?.city?.city_name}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <HouseTwoIcon fill="#407BFF" />
                  <div className="d-flex flex-column align-items-center">
                    <span className="text-on-bg-secondary font-small-2">
                      {t('modality')}
                    </span>
                    <span>{t('remote')}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          {!OUT_OF_THE_APP && (
            <div className="d-flex align-items-center gap-1">
              <ClockJobOfferIcon width={24} height={24} fill="#407BFF" />
              <div className="d-flex flex-column align-items-center">
                <span className="text-on-bg-secondary font-small-2">
                  {IS_COMPANY ? t('creation_date') : t('affer_received')}
                </span>
                <span>{offerDate}</span>
              </div>
            </div>
          )}
          {/* {date_estimated_hiring && (
            <div
              className="d-flex align-items-center gap-1"
              style={{
                marginTop: '-6px',
              }}
            >
              <BriefcaseClockIcon width={24} height={24} fill="#407BFF" />
              <div className="d-flex flex-column align-items-start">
                <div>
                  <span className="text-on-bg-secondary font-small-2">
                    {t('estimated_hiring')}
                  </span>
                  <Tooltip
                    className="cursor-pointer"
                    position="bottom"
                    style={{
                      width: '240px',
                    }}
                    text={t(
                      'this_is_the_approximate_date_when_the_selection_process_is_expected_to_be_completed_and_a_formal_offer_is_made_it_is_not_a_guarantee_or_the_exact_date_of_your_hiring'
                    )}
                  >
                    <QuestionIcon width="12" height="12" pathFill="#6B8282" />
                  </Tooltip>
                </div>
                <span>{estimated_hiring_date ?? t('not_available')}</span>
              </div>
            </div>
          )} */}
        </div>
        {IS_TALENT && (
          <div className="d-flex d-lg-none justify-content-end align-items-center gap-3">
            <Button
              className="btn-on-bg-primary bg-on-bg-primary text-bg-danger border border-bg-danger"
              disabled={
                isLoadingAcceptJobOfferMutate ||
                accept ||
                expired ||
                offer_status_id === 2
              }
              onClick={handleOpenDeclineJobOfferModal}
            >
              {isLoadingAcceptJobOfferMutate ? (
                <Loader />
              ) : (
                <span
                  className={`${
                    viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
                  } inline-text`}
                >
                  {t('decline')}
                </span>
              )}
            </Button>
            <Button
              onClick={handleAcceptJobOffer}
              disabled={
                isLoadingAcceptJobOfferMutate ||
                accept ||
                expired ||
                offer_status_id === 2
              }
              className="btn-bg-CTA-primary text-on-bg-primary"
            >
              {isLoadingAcceptJobOfferMutate ? (
                <Loader />
              ) : (
                <span
                  className={`${
                    viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
                  } inline-text`}
                >
                  {t('accept_now')}
                </span>
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
