const EarringStatusIcon = ({
  width = '12',
  height = '13',
  fill = '#6B4426',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
    >
      <mask
        id="mask0_3098_10725"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="13"
      >
        <rect y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3098_10725)">
        <path
          d="M5.9998 11.5125C5.86647 11.5125 5.73939 11.4875 5.61856 11.4375C5.49772 11.3875 5.3873 11.3167 5.2873 11.225L1.2748 7.21255C1.18314 7.11255 1.1123 7.00213 1.0623 6.8813C1.0123 6.76047 0.987305 6.63338 0.987305 6.50005C0.987305 6.36672 1.0123 6.23755 1.0623 6.11255C1.1123 5.98755 1.18314 5.87922 1.2748 5.78755L5.2873 1.77505C5.3873 1.67505 5.49772 1.60213 5.61856 1.5563C5.73939 1.51047 5.86647 1.48755 5.9998 1.48755C6.13314 1.48755 6.26231 1.51047 6.38731 1.5563C6.51231 1.60213 6.62064 1.67505 6.71231 1.77505L10.7248 5.78755C10.8248 5.87922 10.8977 5.98755 10.9436 6.11255C10.9894 6.23755 11.0123 6.36672 11.0123 6.50005C11.0123 6.63338 10.9894 6.76047 10.9436 6.8813C10.8977 7.00213 10.8248 7.11255 10.7248 7.21255L6.71231 11.225C6.62064 11.3167 6.51231 11.3875 6.38731 11.4375C6.26231 11.4875 6.13314 11.5125 5.9998 11.5125ZM5.9998 10.5125L10.0123 6.50005L5.9998 2.48755L1.9873 6.50005L5.9998 10.5125ZM5.4998 7.00005H6.4998V4.00005H5.4998V7.00005ZM5.9998 8.50005C6.14147 8.50005 6.26022 8.45213 6.35606 8.3563C6.45189 8.26047 6.4998 8.14172 6.4998 8.00005C6.4998 7.85838 6.45189 7.73963 6.35606 7.6438C6.26022 7.54797 6.14147 7.50005 5.9998 7.50005C5.85814 7.50005 5.73939 7.54797 5.64356 7.6438C5.54772 7.73963 5.4998 7.85838 5.4998 8.00005C5.4998 8.14172 5.54772 8.26047 5.64356 8.3563C5.73939 8.45213 5.85814 8.50005 5.9998 8.50005Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default EarringStatusIcon
