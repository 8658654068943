const BriefcaseOutlineIcon = ({ width = '17', height = '17' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
    >
      <mask
        id="mask0_3038_1266"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="17"
      >
        <rect
          x="0.216797"
          y="0.0527344"
          width="16"
          height="16"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_3038_1266)">
        <path
          d="M2.88338 14.0528C2.51672 14.0528 2.20283 13.9222 1.94172 13.6611C1.6806 13.4 1.55005 13.0861 1.55005 12.7194V5.38611C1.55005 5.01944 1.6806 4.70555 1.94172 4.44444C2.20283 4.18333 2.51672 4.05278 2.88338 4.05278H5.55005V2.71944C5.55005 2.35278 5.6806 2.03889 5.94172 1.77778C6.20283 1.51666 6.51672 1.38611 6.88338 1.38611H9.55005C9.91671 1.38611 10.2306 1.51666 10.4917 1.77778C10.7528 2.03889 10.8834 2.35278 10.8834 2.71944V4.05278H13.55C13.9167 4.05278 14.2306 4.18333 14.4917 4.44444C14.7528 4.70555 14.8834 5.01944 14.8834 5.38611V12.7194C14.8834 13.0861 14.7528 13.4 14.4917 13.6611C14.2306 13.9222 13.9167 14.0528 13.55 14.0528H2.88338ZM2.88338 12.7194H13.55V5.38611H2.88338V12.7194ZM6.88338 4.05278H9.55005V2.71944H6.88338V4.05278Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default BriefcaseOutlineIcon
