import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getProfileUser } from '../../services/MyProfile'
import { TAGS } from '../../services/Tags'
import { IS_COMPANY, OUT_OF_THE_APP, VIEWPORT_LG } from '../../utils/constants'
import LongWaitTimeBanner from './components/LongWaitTimeBanner'
import WaitingCreateFirstOffer from './components/WaitingCreateFirstOffer'
import { CALENDLY_API_URL } from '../../services/constants'
import useResize from '../../hooks/useResize'
import schedule_call_banner_mobile from './icons/schedule_call_banner_mobile.svg'
import ScheduleCallBannerSkeleton from './skeleton/ScheduleCallBannerSkeleton'

const ScheduleCallBanner = () => {
  const viewportSize = useResize()
  const isMobile = viewportSize[0] <= VIEWPORT_LG
  const { data: profileUserData = {}, isLoading = false } = useQuery(
    [`${TAGS.profile_data}-schedule-call-banner`],
    getProfileUser,
    {
      enabled: !OUT_OF_THE_APP && IS_COMPANY,
    }
  )

  const { userInfo = {} } = profileUserData ?? {}
  const { validated_account = false, first_offer_created } = userInfo ?? {}

  if (!IS_COMPANY) {
    return <></>
  }

  const renderVersion = isMobile ? (
    <img width={336} src={schedule_call_banner_mobile} />
  ) : (
    <LongWaitTimeBanner />
  )

  if (isLoading) return <ScheduleCallBannerSkeleton />

  return (
    <div className="mt-3 mb-3">
      <Link to={CALENDLY_API_URL} target="_blank" className="cursor-pointer">
        {first_offer_created ? (
          validated_account ? (
            <></>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-center">{renderVersion}</div>
          )
        ) : (
          <WaitingCreateFirstOffer />
        )}
      </Link>
    </div>
  )
}

export default ScheduleCallBanner
