const ArrowLeftBentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 17L9.425 15.6L4.825 11L9.425 6.4L8 5L2 11L8 17ZM22 19V15C22 13.6167 21.5125 12.4375 20.5375 11.4625C19.5625 10.4875 18.3833 10 17 10H10.825L14.425 6.4L13 5L7 11L13 17L14.425 15.6L10.825 12H17C17.8333 12 18.5417 12.2917 19.125 12.875C19.7083 13.4583 20 14.1667 20 15V19H22Z"
        fill="#004949"
      />
    </svg>
  )
}

export default ArrowLeftBentIcon
