import Skeleton from 'react-loading-skeleton'

const TalentCardSkeleton = ({ isFullWidth = false }) => {
  return (
    <Skeleton
      count={5}
      style={{
        width: isFullWidth ? '100%' : '371px',
        height: '148px',
      }}
    />
  )
}

export default TalentCardSkeleton
