const XDangerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <mask
        id="mask0_3223_12388"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="13"
      >
        <rect y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3223_12388)">
        <path
          d="M3.2 10L2.5 9.3L5.3 6.5L2.5 3.7L3.2 3L6 5.8L8.8 3L9.5 3.7L6.7 6.5L9.5 9.3L8.8 10L6 7.2L3.2 10Z"
          fill="#EA3D29"
        />
      </g>
    </svg>
  )
}

export default XDangerIcon
