import { token } from '../../localStorage'
import { BASE_URL } from '../constants'

export const listOfNpsTickets = () => {
  const URL = `${BASE_URL}master/discoverySource/list?socialNetworksId&dateBefore&dateAfter`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    }).then((response) => response.json())

    return response
  } catch (error) {
    console.log(error)
  }
}

export const sendNpsInfo = async (data) => {
  const URL = `${BASE_URL}user/discoverySource/create`

  const rawBody = JSON.stringify(data)

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}
