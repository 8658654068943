const ClockIcon = ({ fill = '#6B8282', width = 12, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/schedule">
        <mask
          id="mask0_8573_7667"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="13"
          height="13"
        >
          <rect
            id="Bounding box"
            x="0.000244141"
            y="0.5"
            width="12"
            height="12"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_8573_7667)">
          <path
            id="icon"
            d="M7.65024 8.85L8.35024 8.15L6.50024 6.3V4H5.50024V6.7L7.65024 8.85ZM6.00024 11.5C5.30858 11.5 4.65858 11.3687 4.05024 11.1062C3.44191 10.8438 2.91274 10.4875 2.46274 10.0375C2.01274 9.5875 1.65649 9.05833 1.39399 8.45C1.13149 7.84167 1.00024 7.19167 1.00024 6.5C1.00024 5.80833 1.13149 5.15833 1.39399 4.55C1.65649 3.94167 2.01274 3.4125 2.46274 2.9625C2.91274 2.5125 3.44191 2.15625 4.05024 1.89375C4.65858 1.63125 5.30858 1.5 6.00024 1.5C6.69191 1.5 7.34191 1.63125 7.95024 1.89375C8.55858 2.15625 9.08774 2.5125 9.53774 2.9625C9.98774 3.4125 10.344 3.94167 10.6065 4.55C10.869 5.15833 11.0002 5.80833 11.0002 6.5C11.0002 7.19167 10.869 7.84167 10.6065 8.45C10.344 9.05833 9.98774 9.5875 9.53774 10.0375C9.08774 10.4875 8.55858 10.8438 7.95024 11.1062C7.34191 11.3687 6.69191 11.5 6.00024 11.5ZM6.00024 10.5C7.10858 10.5 8.05233 10.1104 8.83149 9.33125C9.61066 8.55208 10.0002 7.60833 10.0002 6.5C10.0002 5.39167 9.61066 4.44792 8.83149 3.66875C8.05233 2.88958 7.10858 2.5 6.00024 2.5C4.89191 2.5 3.94816 2.88958 3.16899 3.66875C2.38983 4.44792 2.00024 5.39167 2.00024 6.5C2.00024 7.60833 2.38983 8.55208 3.16899 9.33125C3.94816 10.1104 4.89191 10.5 6.00024 10.5Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export default ClockIcon
