const TranslateIcon = ({ fill = '#00CF83', width = '24', height = '24' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_889_13996"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_889_13996)">
        <path
          d="M11.9005 22.0002L16.4505 10.0002H18.5505L23.1005 22.0002H21.0005L19.9505 18.9502H15.1005L14.0005 22.0002H11.9005ZM15.7005 17.2002H19.3005L17.5505 12.2502H17.4505L15.7005 17.2002ZM4.00049 19.0002L2.60049 17.6002L7.65049 12.5502C7.01716 11.8502 6.46299 11.1252 5.98799 10.3752C5.51299 9.62524 5.10049 8.83358 4.75049 8.00024H6.85049C7.15049 8.60024 7.47132 9.14191 7.81299 9.62524C8.15465 10.1086 8.56715 10.6169 9.05049 11.1502C9.78382 10.3502 10.3922 9.52941 10.8755 8.68774C11.3588 7.84608 11.7672 6.95024 12.1005 6.00024H1.00049V4.00024H8.00049V2.00024H10.0005V4.00024H17.0005V6.00024H14.1005C13.7505 7.18358 13.2755 8.33358 12.6755 9.45024C12.0755 10.5669 11.3338 11.6169 10.4505 12.6002L12.8505 15.0502L12.1005 17.1002L9.00049 14.0002L4.00049 19.0002Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default TranslateIcon
