import { BASE_URL } from '../constants'

const token = JSON.parse(localStorage.getItem('token'))

export const createOfferManagement = async (data) => {
  const rawBody = JSON.stringify({
    ...data,
    startingAmount: Number(data?.startingAmount),
    finalAmount: Number(data?.finalAmount),
  })

  const URL = `${BASE_URL}/createoffer`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    const res = await response.json()

    return { response, res }
  } catch (error) {
    console.log(error)
  }
}

export const createOfferAssociate = async (data) => {
  const rawBody = JSON.stringify({
    ...data,
    startingAmount: Number(data?.startingAmount),
    finalAmount: Number(data?.finalAmount),
  })

  const URL = `${BASE_URL}user/createOfferAssociate`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    const res = await response.json()

    return { response, res }
  } catch (error) {
    console.log(error)
  }
}

export const createJobOfferAssociate = async (data) => {
  const rawBody = JSON.stringify({
    ...data,
    startingAmount: Number(data?.startingAmount),
    finalAmount: Number(data?.finalAmount),
  })

  const URL = `${BASE_URL}createOfferAssociate`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    const res = await response.json()

    return { response, res }
  } catch (error) {
    console.log(error)
  }
}

export const getMyOffersManagement = ({ page, search = '' }) => {
  const URL = `${BASE_URL}/getofferscompany?page=${page || 0}&search=${
    search || ''
  }`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const hideOfferManagement = (data) => {
  const URL = `${BASE_URL}/hidenoffer`

  const rawBody = JSON.stringify({
    idOffer: data,
  })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getMyOffersManagementDetail = (id, isCandidate = false) => {
  const URL = `${BASE_URL}getoneofferidcompany/${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => (isCandidate ? res || [] : res.offer || []))

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateOfferManagement = (data) => {
  const URL = `${BASE_URL}/trampaapiupdate`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getMyOffersTalent = (selectedButton = 0) => {
  const URL = `${BASE_URL}/getmyofferstalent?idState=${selectedButton}&page=0`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.offers || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getMyOfferTalentDetail = (offerManagementId, isPublicService) => {
  const PUBLIC_URL = `${BASE_URL}/public/offers/one?offerId=${offerManagementId}`
  const URL = `${BASE_URL}/getoneofferidtalent/${offerManagementId}`

  try {
    const response = fetch(isPublicService ? PUBLIC_URL : URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.offer || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getPublicJobOfferDetail = (offerManagementId) => {
  const PUBLIC_URL = `${BASE_URL}/public/offers/one?offerId=${offerManagementId}`

  try {
    const response = fetch(PUBLIC_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => res.offer || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfPublicVacancies = () => {
  const PUBLIC_URL = `${BASE_URL}/public/offers/list?page=0`

  try {
    const response = fetch(PUBLIC_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const sendOfferTalent = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/sendSimpleOffer`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const res = await response.json()

    return {
      response,
      res,
    }
  } catch (error) {
    console.log(error)
  }
}

export const sendGroupOffersTalent = (data) => {
  const rawBody = JSON.stringify(data)
  const URL = `${BASE_URL}sendgroupoffers`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const hideOfferTalent = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}/hidenoffer`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const acceptJobOffer = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/accept-offer`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const declineJobOffer = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/rejectin-offer`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getMySentOffers = (data) => {
  const URL = `${BASE_URL}/getmysendoffers/${data.page}-${data.param}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.offersSend || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const activateOfferManagement = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}/hangestateoffer`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createFastOfferManagement = ({ data, token }) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}order/createOffers`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfReasonForDeclineJobOffer = () => {
  const URL = `${BASE_URL}getReasons`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.reasons || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfCoins = () => {
  const URL = `${BASE_URL}getCoins`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.coins || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getStatesOffer = () => {
  const URL = `${BASE_URL}getStatesOffer`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.statesOffer || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateOffer = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/updateOffer`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}
