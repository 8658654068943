import useResize from '../../../hooks/useResize'
import { VIEWPORT_LG } from '../../../utils/constants'
import waiting_icon from './waiting_icon.svg'

const WaitingCreateFirstOffer = () => {
  const viewportSize = useResize()
  const isMobile = viewportSize[0] <= VIEWPORT_LG
  return (
    <img
      src={waiting_icon}
      width={isMobile ? 336 : 776}
      height={isMobile ? 112 : 64}
      className={
        isMobile ? 'd-flex align-items-center justify-content-center w-100' : ''
      }
    />
  )
}

export default WaitingCreateFirstOffer
