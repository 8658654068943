import Skeleton from 'react-loading-skeleton'

export const JobOfferDetailSkeleton = ({ width = '900px', height = '1000px' }) => {
  return (
    <Skeleton
      style={{
        width,
        height,
      }}
    />
  )
}
