const CheckSmallIcon = ({ fill = '#157A08', width = '13', height = '13' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
    >
      <mask
        id="mask0_3343_14466"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="13"
        height="13"
      >
        <rect x="0.333496" y="0.358398" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3343_14466)">
        <path
          d="M5.1083 9.3582L2.2583 6.5082L2.9708 5.7957L5.1083 7.9332L9.6958 3.3457L10.4083 4.0582L5.1083 9.3582Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default CheckSmallIcon
