/* eslint-disable no-unused-vars */
import Wrapper from '../../components/Wrapper/Wrapper'
import banner_public_vacancies from '../../assets/banner_public_vacancies.png'
import JobOfferDetailPublic from '../JobOffers/components/JobOfferDetailPublic'
import ListOfVacancies from './components/ListOfVacancies'
import { listOfPublicVacancies } from '../../services/OfferManagement'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LOCAL_STORAGE_VALUES } from '../../localStorage'
import dayjs from 'dayjs'
import { ListOfJobOffersSkeleton } from '../../skeleton/Global/JobOffers/ListOfJobOffersSkeleton'
import { PUBLIC_ROUTES } from '../../Routes/routes'
import useResize from '../../hooks/useResize'
import { VIEWPORT_LG } from '../../utils/constants'
import banner_public_vacancies_mobile from '../../assets/banner_public_vacancies_mobile.png'
import { useVacanciesStore } from '../../store'

const Vacancies = () => {
  const { id, setId } = useVacanciesStore()
  const { invitationCode, offerManagementId } = useParams()
  const navigate = useNavigate()
  const viewportSize = useResize()
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  const currentInvitationCodeLocalStorage = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_VALUES.currentInvitationCodeExpired)
  )

  const {
    data: listOfPublicVacanciesData = {},
    isLoading: isInitialLoadingListOfPublicVacancies,
  } = useQuery(['list-of-public-vacancies'], listOfPublicVacancies)

  const { offers: vacancies } = listOfPublicVacanciesData ?? {}

  useEffect(() => {
    const addOverflowHidden = () => {
      document.body.style.overflow = 'hidden'
    }

    const removeOverflowHidden = () => {
      document.body.style.overflow = ''
    }

    if (!isMobile) {
      addOverflowHidden()
    }

    return removeOverflowHidden
  }, [])

  useEffect(() => {
    if (invitationCode !== undefined) {
      if (invitationCode === currentInvitationCodeLocalStorage) {
        return
      } else {
        localStorage.setItem('invitationCode', JSON.stringify(invitationCode))
        localStorage.setItem(
          LOCAL_STORAGE_VALUES.currentInvitationCodeExpired,
          JSON.stringify(invitationCode)
        )
        localStorage.setItem(
          LOCAL_STORAGE_VALUES.invitationCodeTimeExpired,
          JSON.stringify(dayjs())
        )
      }
    }
  }, [invitationCode])

  useEffect(() => {
    setId(offerManagementId)
  }, [])

  return (
    <Wrapper disableStyles activeFooter={false}>
      <div className="d-flex flex-column align-items-center justify-content-center mt-lg-4 mt-2">
        <img
          className={`${
            isMobile ? (!id ? 'd-flex' : 'd-none') : ''
          } cursor-pointer ${
            isInitialLoadingListOfPublicVacancies ? 'd-none' : ''
          }`}
          src={
            isMobile ? banner_public_vacancies_mobile : banner_public_vacancies
          }
          width={isMobile ? 350 : 1200}
          onClick={() => navigate(PUBLIC_ROUTES.register_talent)}
        />

        <div
          className={`${
            isMobile
              ? 'w-100'
              : 'd-flex align-items-start w-100 justify-content-center gap-4'
          } `}
        >
          <div>
            {isInitialLoadingListOfPublicVacancies ? (
              <ListOfJobOffersSkeleton width="355px" height="148px" />
            ) : (
              <ListOfVacancies listOfPublicVacanciesData={vacancies} />
            )}
          </div>
          {id && <JobOfferDetailPublic />}
        </div>
      </div>
    </Wrapper>
  )
}

export default Vacancies
