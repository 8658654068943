import useResize from '../../../hooks/useResize'
import CashIcon from '../../../icons/CashIcon'
import TranslateIcon from '../../../icons/TranslateIcon'
import { formatCurrency } from '../../../utils'
import { VIEWPORT_LG } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import error_load_icon from '../../../assets/error_load_icon.png'
import BriefcaseClockIcon from '../../../icons/BriefcaseClockIcon'

const Requirements = ({
  offert_lang = [],
  range_year,
  starting_amount,
  final_amount,
  coin,
  isDownloadOfferView = false,
}) => {
  const viewportSize = useResize()
  const { t } = useTranslation()
  const isMobile = viewportSize[0] <= VIEWPORT_LG;

  const renderOffertLang = offert_lang ?? []

  return (
    <div className="w-100">
      <span className="fw-bold text-on-bg-primary">{t('requirements')}</span>
      <div className="bg-white mt-2 p-3 mb-3 rounded">
        <div className="text-white d-flex align-items-center gap-1 gap-lg-3 mt-2 mb-1 overflow-auto">
          <div
            className={`${
              viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
            } d-none align-items-center gap-1 inline-text text-on-bg-secondary`}
          >
            <CashIcon />
            <span>
              {formatCurrency({
                amount: String(starting_amount),
                symbol: coin?.symbol,
                short_name: final_amount === 0 ? coin?.short_name : '',
              })}
            </span>
            {final_amount !== 0 && (
              <span>
                {' '}
                -{' '}
                {formatCurrency({
                  amount: String(final_amount),
                  symbol: coin?.symbol,
                  short_name: coin?.short_name,
                })}{' '}
              </span>
            )}{' '}
            {t('annual_gross')}
          </div>
        </div>
        <div className="d-flex align-items-center font-small gap-1">
          <span className="text-on-bg-primary fw-bolder">{t('languages')}</span>
          <TranslateIcon fill="#004949" width="14" height="14" />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '50% 50%' : '33% 33% 33%',
          }}
        >
          {renderOffertLang?.map(({ id, languaje, lang_level }, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-wrap flex-row align-items-center gap-1"
              >
                <img
                  className="rounded-pill"
                  src={languaje?.img ?? error_load_icon}
                  width={12}
                  height={12}
                />
                <span
                  key={id}
                  className={`${
                    viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
                  } inline-text text-on-bg-secondary`}
                  style={{
                    fontSize: isDownloadOfferView ? '10px' : '',
                  }}
                >
                  {languaje?.name ?? ''}
                </span>
                {!isDownloadOfferView && (
                  <span
                    key={id}
                    className={`${
                      isDownloadOfferView ? '' : 'font-extra-small'
                    } inline-text text-on-bg-secondary`}
                    style={{
                      fontSize: isDownloadOfferView ? '10px' : '',
                    }}
                  >
                    {`(${lang_level?.name ?? ''})`}
                  </span>
                )}
              </div>
            )
          })}
        </div>
        <div className="d-flex flex-column mt-2">
          <div className="d-flex align-items-center gap-1">
            <span className="font-small text-on-bg-primary fw-bolder">
              {t('years_of_experience')}
            </span>
            <BriefcaseClockIcon width={14} height={14} />
          </div>
          <span
            className={`${
              viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : ''
            } inline-text text-on-bg-secondary`}
            style={{
              fontSize: isDownloadOfferView ? '10px' : '12px',
            }}
          >{`${range_year?.years || 'Sin años'}`}</span>
        </div>
      </div>
    </div>
  )
}

export default Requirements
