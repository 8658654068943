import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useResize from '../../../hooks/useResize'
import { useMutation, useQuery } from '@tanstack/react-query'
import { TAGS } from '../../../services/Tags'
import { getCategories } from '../../../services/talents'
import {
  IS_COMPANY,
  LIMIT_OF_CHARACTERS,
  VIEWPORT_LG,
} from '../../../utils/constants'
import { getCities, getCountries, getStates } from '../../../services/Adress'
import {
  getMyOffersManagementDetail,
  listOfCoins,
  updateOffer,
} from '../../../services/OfferManagement'
import { useEffect, useState } from 'react'
import DashboardWrapper from '../../Dashboard/components/DashboardWrapper'
import Loader from '../../../components/Loader'
import HelperIconTwo from '../../../icons/HelperIconTwo'
import { ErrorMessage } from '@hookform/error-message'
import {
  ENGLISH_LEVEL,
  EXPERIENCE_LEVEL,
} from '../../../utils/constants/filters'
import Button from '../../../components/Button'
import Dollar from '../../../icons/coins/Dollar'
import Europe from '../../../icons/coins/Europe'
import RegisterFastIconFour from '../../../icons/RegisterFastIconFour'
import Toast from '../../../components/Toast/Toast'
import { getlanguages } from '../../../services/Admin'
import DisableTrashIcon from '../../../icons/DisableTrashIcon'
import TrashIcon from '../../../icons/TrashIcon'
import { findRepeatedElements } from '../../../utils'
//import dayjs from 'dayjs'
import InnerBanner from '../../../components/InnerBanner'
import { PRIVATE_ROUTES } from '../../../Routes/routes'
import SelectSkills from '../../../components/SelectSkills'

const languagesValues = {
  idLang: '',
  lang_level_id: '',
}

const initialValues = {
  name: '',
  position: '',
  langOffer: [languagesValues],
  nativeLanguage: {
    idLang: '',
    lang_level_id: 7,
  },
  skillsOffer: [],
  startingAmount: '',
  finalAmount: '',
  yearsOfExperienceOffer: '',
  modalitys: 0,
  description: '',
  it_is_value: '',
  idRegularitySalary: '',
  bonus: 0,
  IdCategorie: 1,
  idCoin: null,
  //dateEstimatedHiring: null,
  address: {
    country: '',
    state: '',
    city: '',
  },
}

const EditJobOfferForm = () => {
  const viewportSize = useResize()
  const { t } = useTranslation()
  const { id } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({ defaultValues: initialValues })

  const {
    fields: languagesFields = [],
    append: appendLanguages = () => {},
    remove: removeLanguages = () => {},
    replace: replaceLanguages = () => {},
  } = useFieldArray({
    control,
    name: 'langOffer',
  })

  const yearsOfExperienceOffer = watch('yearsOfExperienceOffer')
  const langOffer = watch('langOffer')
  const nativeLanguage = watch('nativeLanguage')
  const startingAmount = watch('startingAmount')
  const it_is_value = watch('it_is_value')
  const description = watch('description')
  const idCoin = watch('idCoin')
  const position = watch('position')
  const modalitys = watch('modalitys')
  const country = watch('address.country')
  const state = watch('address.state')
  const city = watch('address.city')

  const [repeatedLanguages, setRepeatedLanguages] = useState([])
  const [newLanguages, setNewLanguages] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [checkedTypeOfSalary, setCheckedTypeOfSalary] = useState(false)

  const listOfLanguages = [nativeLanguage, ...langOffer]
  const lastItemInTheLanguagesArray = langOffer?.[langOffer?.length - 1]
  const transformLanguagesFields = langOffer?.map(({ idLang }) => idLang)

  const {
    data: getMyOffersManagementDetailData = [],
    isInitialLoading: isLoadingGetMyOffersManagementDetail,
  } = useQuery(
    [TAGS.offer_management_detail, id],
    () => getMyOffersManagementDetail(id),
    { enabled: IS_COMPANY }
  )

  const { data: categoriesData = [] } = useQuery(
    [TAGS.categories],
    getCategories
  )

  const { data: listOfCoinsData = [] } = useQuery(
    [TAGS.list_of_coins, id],
    listOfCoins
  )

  const { data: dataContries } = useQuery([TAGS.list_of_contries], getCountries)

  const { data: dataStates } = useQuery({
    queryKey: [TAGS.list_of_states, country || 0],
    queryFn: () => getStates(country || 0),
    enabled: country !== '',
  })

  const { data: dataCities } = useQuery({
    queryKey: [TAGS.list_of_cities, state || 0],
    queryFn: () => getCities(state || 0),
    enabled: state !== '',
  })

  const { data: listLanguagesData } = useQuery(
    [TAGS.list_of_idioms],
    getlanguages
  )

  const preloadSelectedSkills =
    getMyOffersManagementDetailData?.offert_skills?.map(
      ({ skill_id, skills_categori }) => {
        return {
          label: skills_categori?.skill?.name,
          value: skill_id,
        }
      }
    )

  const { mutate: updateOfferMutate, isLoading: isLoadingUpdateOffer } =
    useMutation(['updateOffer'], updateOffer, {
      onSuccess: async ({ status }) => {
        if (status === 200) {
          await Toast({
            title:
              'Oferta editada correctamente... Redirigiendo al detalle de la oferta',
            timer: 2000,
          })
          window.location.href = `/offer-management/${id}`
        }
      },
    })

  const transformDataContries = dataContries?.map(({ id, country_name }) => ({
    id,
    name: country_name,
  }))

  const transformDataStates = dataStates?.map(({ id, state_name }) => ({
    id,
    name: state_name,
  }))

  const transformDataCities = dataCities?.map(({ id, city_name }) => ({
    id,
    name: city_name,
  }))

  const getCategory = categoriesData?.find(
    (props) => Number(props?.id) === Number(position)
  )

  const disabledStateInput = country === ''
  const disabledCityInput = state === ''

  const parseSelectedSkills = selectedSkills?.map((skill) => {
    return {
      idSkill: skill?.value,
    }
  })
  

  const transformCurrrentLanguages =
    getMyOffersManagementDetailData?.offert_langs
      ?.filter((props) => Number(props?.lang_level?.id) !== 7)
      ?.map((props) => {
        return {
          idLang: props?.languaje?.id,
          lang_level_id: props?.lang_level?.id,
        }
      })

  const findNativeLanguage =
    getMyOffersManagementDetailData?.offert_langs?.find(
      (props) => Number(props?.lang_level?.id) === 7
    )

  const onSubmit = (data) => {
    if (selectedSkills?.length > 12) {
      return
    }
    const customData = {
      ...data,
      dateEstimatedHiring: '2025-01-01',
      skillsOffer: parseSelectedSkills,
      position: getCategory?.name,
      langOffer: listOfLanguages.filter((props) => props?.idLang !== ''),
      IdCategorie: getCategory?.id,
      idOffer: id,
      modalitys: [Number(modalitys)],
      address:
        Number(modalitys) === 3 || Number(modalitys) === 1
          ? {
              country,
              region: state,
              city,
            }
          : null,
    }
    updateOfferMutate(customData)
  }

  const handleYearsOfExperienceChange = (event) => {
    const checkboxValue = event.target.value
    setValue('yearsOfExperienceOffer', checkboxValue)
  }

  const handleModalityChange = (event) => {
    const checkboxValue = event.target.value
    setValue('modalitys', checkboxValue)
  }

  const handleInputChange = ({ event, value = '' }) => {
    setValue(value, event.target.value.replace(/\D/g, ''))
  }

  useEffect(() => {
    if (!checkedTypeOfSalary) {
      setValue('finalAmount', 0)
    }
  }, [checkedTypeOfSalary])

  useEffect(() => {
    if (getMyOffersManagementDetailData) {
      if (getMyOffersManagementDetailData?.offert_langs?.length > 0) {
        replaceLanguages(transformCurrrentLanguages ?? [])
        setValue('nativeLanguage.idLang', findNativeLanguage?.languaje?.id)
      }
      setValue('position', getMyOffersManagementDetailData?.id_categorie)
      setValue('name', getMyOffersManagementDetailData?.name)

      setValue('yearsOfExperienceOffer', [
        String(getMyOffersManagementDetailData?.id_years_exp),
      ])
      setValue(
        'startingAmount',
        getMyOffersManagementDetailData?.starting_amount
      )

      if (getMyOffersManagementDetailData?.final_amount > 0) {
        setValue('finalAmount', getMyOffersManagementDetailData?.final_amount)
      }

      setValue(
        'idRegularitySalary',
        getMyOffersManagementDetailData?.type_annual_salary?.id
      )

      setValue('it_is_value', getMyOffersManagementDetailData?.it_is_value)
      setValue('description', getMyOffersManagementDetailData?.description)

      setValue(
        'modalitys',
        getMyOffersManagementDetailData?.modality_offerts?.[0]?.modality?.id
      )
      setValue(
        'address.country',
        getMyOffersManagementDetailData?.address?.country?.id
      )
      setValue(
        'address.state',
        getMyOffersManagementDetailData?.address?.state?.id
      )
      setValue(
        'address.city',
        getMyOffersManagementDetailData?.address?.city?.id
      )

      // setValue(
      //   'dateEstimatedHiring',
      //   getMyOffersManagementDetailData?.date_estimated_hiring
      //     ? dayjs(getMyOffersManagementDetailData?.date_estimated_hiring)
      //         .add(1, 'day')
      //         .format('YYYY-MM-DD')
      //     : null
      // )

      setValue('idCoin', getMyOffersManagementDetailData?.coin?.id)
    }
  }, [getMyOffersManagementDetailData, categoriesData, listLanguagesData])

  useEffect(() => {
    if (getMyOffersManagementDetailData) {
      getMyOffersManagementDetailData?.final_amount > 0 &&
        setCheckedTypeOfSalary(true)
    }
  }, [getMyOffersManagementDetailData])

  useEffect(() => {
    setValue('skillsOffer', parseSelectedSkills)
  }, [selectedSkills])

  useEffect(() => {
    if (
      lastItemInTheLanguagesArray?.idLang !== '' &&
      lastItemInTheLanguagesArray?.lang_level_id !== ''
    ) {
      appendLanguages(languagesValues)
    }
  }, [
    lastItemInTheLanguagesArray?.idLang,
    lastItemInTheLanguagesArray?.lang_level_id,
  ])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setNewLanguages(transformLanguagesFields)
    }, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    setRepeatedLanguages(findRepeatedElements(transformLanguagesFields))
  }, [newLanguages])

  return (
    <DashboardWrapper dashboardWrapperClassName="w-100">
      {isLoadingGetMyOffersManagementDetail ? (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <Loader />
        </div>
      ) : (
        <div
          className={`${
            viewportSize[0] <= VIEWPORT_LG ? '' : 'stepper-container'
          } d-flex flex-lg-row flex-column-reverse justify-content-center align-items-center align-items-lg-start p-lg-0 p-3`}
        >
          {' '}
          <div className="w-100">
            <InnerBanner
              className="mb-3"
              before={t('my_offers')}
              beforeRoute={PRIVATE_ROUTES.list_of_offers}
              text={t('offer_edition')}
            />
            <h2 className="text-bg-brand fw-bolder d-none d-lg-inline-block">
              {t('edit_offer')}
            </h2>
            <form
              className={`${
                viewportSize[0] <= VIEWPORT_LG ? 'w-100' : 'w-75'
              } mt-3 mt-lg-5`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mt-4 mb-3">
                <label className="d-flex align-items-center gap-2 form-label text-on-bg-primary">
                  <span>{t('offer_name')}</span>

                  <div
                    title={t(
                      'assign_a_name_so_you_can_identify_your_offers_faster_this_information_will_not_be_visible_to_the_talent'
                    )}
                  >
                    <HelperIconTwo />
                  </div>
                </label>

                <input
                  className="form-control"
                  type="text"
                  placeholder={t('assign_a_name_to_the_offer')}
                  {...register('name', {
                    required: t('this_field_is_required'),
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
              </div>
              <div className="mb-3 sector-company">
                <label className="form-label text-on-bg-primary">
                  {t('position_offered')}
                </label>
                <select
                  id="category-select"
                  className="form-control"
                  {...register('position', {
                    required: t('this_field_is_required'),
                  })}
                >
                  <option disabled value="">
                    {t('choose_the_position')}
                  </option>
                  {categoriesData?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  errors={errors}
                  name="position"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
              </div>
              <div className="mb-3 mt-2 bg-bg-secondary p-3 shadow-sm rounded">
                <label className="text-on-bg-primary fw-bolder">
                  {t('languages')}
                </label>
                <div className="mt-3">
                  <label className="text-on-bg-primary mb-2">
                    {t('native')}
                  </label>

                  <div className="mb-3 w-50">
                    <select
                      className="form-control"
                      {...register('nativeLanguage.idLang', {
                        required: {
                          value: true,
                          message: t('required'),
                        },
                      })}
                    >
                      <option disabled value="">
                        {t('select_a_languages')}
                      </option>
                      {listLanguagesData?.map(({ id, name }) => (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                    <div className="w-25">
                      <ErrorMessage
                        errors={errors}
                        name="nativeLanguages.idLang"
                        render={({ message }) => (
                          <span className="text-danger font-small">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                  </div>
                  <label className="text-on-bg-primary mb-2">
                    {t('complementary')}
                  </label>
                  <div className="d-flex flex-column-reverse">
                    {languagesFields.map((item, index) => {
                      return (
                        <li
                          key={item.id}
                          className={`${
                            item?.idLang !== 'gap-1' && 'gap-3'
                          } d-flex flex-row  mb-1 `}
                        >
                          <div className="w-100">
                            <select
                              className="form-control"
                              {...register(`langOffer.${index}.idLang`, {
                                required: {
                                  value:
                                    langOffer?.length <= 1 ||
                                    lastItemInTheLanguagesArray?.lang_level_id !==
                                      '',
                                  message: t('required'),
                                },
                              })}
                            >
                              <option disabled value="">
                                {t('select_a_languages')}
                              </option>
                              {listLanguagesData?.map(({ id, name }) => (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              ))}
                            </select>
                            <div className="w-25">
                              <ErrorMessage
                                errors={errors}
                                name={`langOffer.${index}.idLang`}
                                render={({ message }) => (
                                  <span className="text-danger font-small">
                                    {message}
                                  </span>
                                )}
                              />
                            </div>
                          </div>
                          <div className="w-100">
                            <select
                              className="form-control sector-company"
                              {...register(`langOffer.${index}.lang_level_id`, {
                                required: {
                                  value:
                                    langOffer?.length <= 1 ||
                                    lastItemInTheLanguagesArray?.idLang !== '',
                                  message: t('required'),
                                },
                              })}
                            >
                              <option disabled value="">
                                {t('select_your_level')}
                              </option>
                              {ENGLISH_LEVEL?.map(({ id, name }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))}
                            </select>

                            <div className="w-25">
                              <ErrorMessage
                                errors={errors}
                                name={`langOffer.${index}.lang_level_id`}
                                render={({ message }) => (
                                  <span className="text-danger font-small">
                                    {message}
                                  </span>
                                )}
                              />
                            </div>
                          </div>
                          <div
                            title={t('remove_language')}
                            className="text-secondary cursor-pointer"
                            onClick={() => {
                              if (index === langOffer?.length - 1) return
                              removeLanguages(index)
                            }}
                          >
                            {index === langOffer?.length - 1 ? (
                              <DisableTrashIcon />
                            ) : (
                              <TrashIcon />
                            )}{' '}
                          </div>
                        </li>
                      )
                    })}
                  </div>
                  <div className="d-flex flex-column">
                    {repeatedLanguages.length > 0 && (
                      <span className="text-danger font-small">
                        {t('you_have_repeated_languages')}
                      </span>
                    )}
                    {langOffer?.length < 1 && (
                      <span className="text-danger font-small">
                        {t('minimum_languages', { value: 1 })}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div id="search-for-skills" className="mt-4">
                <label className="text-on-bg-primary form-label">
                  {t('required_skills')}
                </label>
                <SelectSkills
                  setSelectedSkills={setSelectedSkills}
                  defaultSelectedSkills={preloadSelectedSkills}
                />
              </div>

              <div>
                <div
                  id="years-of-experience"
                  className="d-flex flex-column align-items-star mb-2"
                >
                  <label className="form-label text-on-bg-primary">
                    {t('years_of_experience')}
                  </label>
                  <div>
                    {EXPERIENCE_LEVEL.map(({ name, id }) => (
                      <label key={id} className="custom-checkbox-label me-2">
                        <input
                          value={id}
                          type="checkbox"
                          className="mycheckTwo"
                          checked={Number(yearsOfExperienceOffer) === id}
                          {...register('yearsOfExperienceOffer', {
                            required: t('this_field_is_required'),
                          })}
                          onChange={handleYearsOfExperienceChange}
                        />
                        <span className="custom-checkbox-span text-on-bg-CTA-primary-selected w-100 badge border border-on-bg-CTA-primary-selected rounded rounded-pill cursor-pointer">
                          {name}
                        </span>
                      </label>
                    ))}
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="yearsOfExperienceOffer"
                    render={({ message }) => (
                      <span className="text-danger font-small">{message}</span>
                    )}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label text-on-bg-primary">
                  {t('type_of_salary_offered')}
                </label>
                <div>
                  {listOfCoinsData?.map(({ id, short_name }) => (
                    <label key={id} className="custom-checkbox-label me-2">
                      <input
                        value={id}
                        type="radio"
                        className="mycheckTwo"
                        checked={String(idCoin) === String(id)}
                        {...register('idCoin', {
                          required: t('this_field_is_required'),
                        })}
                      />
                      <span className="custom-checkbox-span text-on-bg-CTA-primary-selected w-100 badge border border-on-bg-CTA-primary-selected rounded rounded-pill cursor-pointer">
                        <div className="d-flex align-items-center gap-2">
                          {id === 1 ? <Dollar /> : <Europe />}
                          {short_name}
                        </div>
                      </span>
                    </label>
                  ))}
                </div>
                <ErrorMessage
                  errors={errors}
                  name="idCoin"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
              </div>
              <div className="mb-3">
                <label className="text-on-bg-primary mb-2">
                  {t('annual_offered_salary')}
                </label>
                <div className="d-flex gap-2">
                  <div className={!checkedTypeOfSalary && 'w-100'}>
                    {checkedTypeOfSalary && (
                      <label className="text-on-bg-primary">
                        {t('minimum')}
                      </label>
                    )}
                    <input
                      {...register('startingAmount', {
                        required: t('this_field_is_required'),
                      })}
                      className="form-control rounded border-on-bg-Tertiary p-2"
                      id="first-login-field"
                      placeholder={
                        checkedTypeOfSalary
                          ? `${Number(idCoin) === 1 ? '$' : '€'} Min.`
                          : t('average_amount')
                      }
                      onChange={(event) =>
                        handleInputChange({ event, value: 'startingAmount' })
                      }
                      type="text"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="startingAmount"
                      render={({ message }) => (
                        <span className="text-danger font-small">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                  {checkedTypeOfSalary && (
                    <div>
                      <label className="text-on-bg-primary">
                        {t('maximum')}
                      </label>
                      <input
                        className="form-control"
                        {...register('finalAmount', {
                          required: {
                            value: checkedTypeOfSalary,
                            message: t('this_field_is_required'),
                          },
                          validate: (currentValue) => {
                            if (checkedTypeOfSalary) {
                              return (
                                Number(startingAmount) < Number(currentValue) ||
                                t(
                                  'the_initial_range_must_be_less_than_the_final_range'
                                )
                              )
                            }
                          },
                        })}
                        placeholder={`${Number(idCoin) === 1 ? '$' : '€'} Max.`}
                        onChange={(event) =>
                          handleInputChange({ event, value: 'finalAmount' })
                        }
                        type="text"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="finalAmount"
                        render={({ message }) => (
                          <span className="text-danger font-small">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3 d-flex align-items-center gap-3">
                <input
                  type="checkbox"
                  className="mycheck"
                  checked={checkedTypeOfSalary}
                  onChange={() =>
                    setCheckedTypeOfSalary((prevState) => !prevState)
                  }
                />
                <span className="text-on-bg-primary">
                  {t('do_you_prefer_to_include_a_salary_range')}
                </span>
              </div>
              {/* <div className="mb-3 d-flex flex-column gap-1">
                <label className="form-label text-on-bg-primary">
                  {t('approximate_date_of_hiring')} ({t('optional')})
                </label>
                <input
                  {...register('dateEstimatedHiring')}
                  min={dayjs().format('YYYY-MM-DD')}
                  type="date"
                  className="form-control"
                />
              </div> */}
              <div className="mb-3 mt-4 sector-company">
                <label className="form-label text-on-bg-primary">
                  {t('salary_type')}
                </label>
                <select
                  id="category-select"
                  className="form-control"
                  {...register('idRegularitySalary', {
                    required: t('this_field_is_required'),
                  })}
                >
                  <option disabled value="">
                    {t('choose_the_type_of_salary')}
                  </option>
                  <option value="2">{t('annual')}</option>
                </select>
                <ErrorMessage
                  errors={errors}
                  name="idRegularitySalary"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
              </div>

              <div className="mb-3 d-flex flex-column">
                <label className="form-label text-on-bg-primary">
                  Modalidad
                </label>
                <div className="d-flex gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <input
                      checked={Number(modalitys) === 2}
                      {...register('modalitys', {
                        required: 'Requerido',
                      })}
                      type="checkbox"
                      className="mycheck"
                      value={2}
                      onChange={handleModalityChange}
                    />
                    <span>{t('remote')}</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      {...register('modalitys', {
                        required: 'Requerido',
                      })}
                      checked={Number(modalitys) === 3}
                      type="checkbox"
                      className="mycheck"
                      value={3}
                      onChange={handleModalityChange}
                    />
                    <span>{t('hybrid')}</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      {...register('modalitys', {
                        required: 'Requerido',
                      })}
                      checked={Number(modalitys) === 1}
                      type="checkbox"
                      className="mycheck"
                      value={1}
                      onChange={handleModalityChange}
                    />
                    <span>Presencial</span>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="modalitys"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
                <span className="mt-2 mb-2 text-on-bg-secondary">
                  {t('now_you_can_create_offers_with_hybrid_mode')}
                </span>
                {Number(modalitys) === 3 || Number(modalitys) === 1 ? (
                  <>
                    <div className="d-flex flex-column">
                      <div className="mb-3 col-sm-6 select-border mt-0">
                        <label className=" form-label text-on-bg-primary">
                          {t('country')}
                        </label>
                        <select
                          id="list-of-sectors"
                          className="form-control"
                          {...register('address.country', {
                            required: {
                              message: t('this_field_is_required'),
                              value:
                                Number(modalitys) === 3 ||
                                Number(modalitys) === 1,
                            },
                          })}
                        >
                          <option disabled value="">
                            Ej. España
                          </option>
                          {transformDataContries?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="address.country"
                          render={({ message }) => (
                            <span className="text-danger">{message}</span>
                          )}
                        />
                      </div>
                      <div className="mb-3 col-sm-6 select-border mt-0">
                        <label className=" form-label text-on-bg-primary">
                          {t('state_or_region')}
                        </label>
                        <select
                          id="list-of-sectors"
                          className="form-control"
                          disabled={disabledStateInput}
                          {...register('address.state', {
                            required: {
                              message: t('this_field_is_required'),
                              value:
                                Number(modalitys) === 3 ||
                                Number(modalitys) === 1,
                            },
                          })}
                        >
                          <option disabled value="">
                            Ej. Cataluña
                          </option>
                          {transformDataStates?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="address.state"
                          render={({ message }) => (
                            <span className="text-danger">{message}</span>
                          )}
                        />
                      </div>
                      <div className="mb-3 col-sm-6 select-border mt-0">
                        <label className="form-label text-on-bg-primary">
                          {t('city')}
                        </label>
                        <select
                          id="list-of-sectors"
                          className="form-control"
                          disabled={disabledCityInput}
                          {...register('address.city', {
                            required: {
                              message: t('this_field_is_required'),
                              value:
                                Number(modalitys) === 3 ||
                                Number(modalitys) === 1,
                            },
                          })}
                        >
                          <option disabled value="">
                            Ej. Barcelona
                          </option>
                          {transformDataCities?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="address.city"
                          render={({ message }) => (
                            <span className="text-danger">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label text-on-bg-primary">
                  {t('desired_profile_and_additional_benefits')}
                </label>
                <textarea
                  {...register('it_is_value', {
                    required: t('this_field_is_required'),
                    validate: () => {
                      if (it_is_value?.length > LIMIT_OF_CHARACTERS) {
                        return `${it_is_value?.length}/${LIMIT_OF_CHARACTERS}`
                      }
                    },
                  })}
                  rows="5"
                  className="form-control"
                  id="sector"
                ></textarea>
                <ErrorMessage
                  errors={errors}
                  name="it_is_value"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
              </div>
              <div className="mb-3">
                <label className="form-label text-on-bg-primary">
                  {t(
                    'description_of_the_position_and_functions_to_be_performed'
                  )}
                </label>
                <textarea
                  {...register('description', {
                    required: t('this_field_is_required'),
                    validate: () => {
                      if (description?.length >= LIMIT_OF_CHARACTERS) {
                        return `${description?.length}/${LIMIT_OF_CHARACTERS}`
                      }
                    },
                  })}
                  rows="5"
                  className="form-control"
                  id="sector"
                ></textarea>
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) => (
                    <span className="text-danger font-small">{message}</span>
                  )}
                />
              </div>
              <div
                title={
                  getMyOffersManagementDetailData?.send
                    ? `${t('how_did_you_get_here')} ${t(
                        'jobs_can_only_be_edited_before_they_are_sent_to_talents'
                      )}`
                    : ''
                }
                className="d-flex justify-content-center justify-content-lg-start mt-4 mb-4"
              >
                <Button
                  className="btn-bg-CTA-primary"
                  type="submit"
                  disabled={
                    isLoadingUpdateOffer ||
                    getMyOffersManagementDetailData?.send
                  }
                >
                  {isLoadingUpdateOffer ? <Loader /> : t('save')}
                </Button>
              </div>
            </form>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <RegisterFastIconFour />
            <span
              className={`${
                viewportSize[0] <= VIEWPORT_LG ? 'w-100' : 'w-50'
              } text-on-bg-secondary text-center mt-4`}
            ></span>
          </div>
        </div>
      )}
    </DashboardWrapper>
  )
}

export default EditJobOfferForm
