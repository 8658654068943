import { useMutation, useQueryClient } from '@tanstack/react-query'
import Button from '../../../components/Button'
import Circle from '../../../components/Circle'
import Modal from '../../../components/Modal'
import WarningIcon from '../../../icons/WarningIcon'
import { HUTRIT_PRIMARY_COLOR } from '../../../utils/constants'
import { activateOfferManagement } from '../../../services/OfferManagement'
import { TAGS } from '../../../services/Tags'
import Loader from '../../../components/Loader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { globalBootstrap } from '../../..'
import { useTranslation } from 'react-i18next'

const DesactivateJobOfferModal = ({ currentJobOfferId }) => {
  const { t } = useTranslation()
  const MySwal = withReactContent(Swal)
  const queryClient = useQueryClient()
  const {
    mutate: activateOfferManagementMutate,
    isLoading: isLoadingActivateOfferManagement,
  } = useMutation(
    [TAGS.activate_or_desactivate_job_offer],
    activateOfferManagement,
    {
      onSuccess: ({ status }) => {
        if (status !== 200) {
          MySwal.fire({
            title: <strong>{t('error_in_the_system')}</strong>,
            confirmButtonColor: 'red',
            icon: 'error',
          })
        } else {
          const modal = globalBootstrap.Modal.getOrCreateInstance(
            '#desactivate-job-offer-modal'
          )
          queryClient.invalidateQueries(TAGS.offers_company)
          modal.hide()
          MySwal.fire({
            title: <strong>{t('offer_disabled')}</strong>,
            confirmButtonColor: HUTRIT_PRIMARY_COLOR,
            icon: 'success',
          })
        }
      },
    }
  )

  const handleActivateOrDesactivateJobOffer = () => {
    activateOfferManagementMutate({
      idOffer: currentJobOfferId,
      stateOffer: 'false',
    })
  }

  const handleCloseModal = () => {
    const modal = globalBootstrap.Modal.getOrCreateInstance(
      '#desactivate-job-offer-modal'
    )
    modal.hide()
  }

  return (
    <Modal modalId="desactivate-job-offer-modal" title="">
      <div className="d-flex flex-column justify-content-center align-items-center text-on-bg-primary">
        <Circle className="bg-warning-100 p-2">
          <WarningIcon />
        </Circle>
        <h5 className="fw-bolder mt-3">
          {t('you_are_about_to_deactivate_the_offer')}
        </h5>
        <span className="text-center">
          {t(
            'we_will_notify_the_talent_that_the_offer_is_not_available_you_can_activate_it_again_and_notify_the_talent_to_continue_with_the_selection_process'
          )}
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-2 mt-4 mb-2">
        <Button
          className="btn-white text-on-bg-primary border border-on-bg-primary"
          disabled={isLoadingActivateOfferManagement}
          type="button"
          onClick={handleCloseModal}
        >
          {isLoadingActivateOfferManagement ? <Loader /> : t('cancel')}
        </Button>
        <Button
          className="btn-bg-CTA-primary text-on-bg-primary"
          disabled={isLoadingActivateOfferManagement}
          onClick={handleActivateOrDesactivateJobOffer}
        >
          {isLoadingActivateOfferManagement ? (
            <Loader />
          ) : (
            t('deactivate_offer')
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default DesactivateJobOfferModal
