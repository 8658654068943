import React from 'react'
import Loader from '../../../components/Loader'
import SadFaceIcon from '../../../icons/SadFaceIcon'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Button from '../../../components/Button'

import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'

const DeclineJobOfferModal = ({
  listOfReasonForDeclineJobOfferData = [],
  isloadingListOfReasonForDeclineJobOffer = false,
  isLoadingDeclineJobOffer = false,
  handleOnChange = () => {},
  handleSubmit = () => {},
  onSubmit = () => {},
  register,
  errors,
}) => {
  const MySwal = withReactContent(Swal)

  return MySwal.fire({
    html: (
      <form id="decline-job-offer-modal" onSubmit={handleSubmit(onSubmit)}>
        {isloadingListOfReasonForDeclineJobOffer ? (
          <Loader />
        ) : (
          <div
            id="decline-job-offer-container"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <SadFaceIcon />
            <h4 className="fw-bolder text-on-bg-primary">
              {i18next?.t('tell_us_what_happened')}
            </h4>
            <span className="text-on-bg-secondary">
              {i18next?.t(
                'please_tell_us_more_about_what_you_didnt_like_about_the_offer_so_we_can_send_you_those_that_meet_your_expectations'
              )}
            </span>
            <div className="d-flex flex-column gap-3 mt-4 mb-4">
              {listOfReasonForDeclineJobOfferData.map(({ id, name }) => (
                <div
                  key={id}
                  className="d-flex justify-content-start align-items-start gap-3"
                >
                  <input
                    {...register('idReason')}
                    value={id}
                    type="checkbox"
                    className="mycheck"
                    onChange={handleOnChange}
                  />
                  <span className="text-on-bg-primary font-small">{name}</span>
                </div>
              ))}
              <ErrorMessage
                errors={errors}
                name="idReason"
                render={({ message }) => (
                  <span className="text-danger font-small">{message}</span>
                )}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Button
                type="button"
                className="d-none d-lg-flex btn-white text-on-bg-primary border border-on-bg-primary"
                disabled={isLoadingDeclineJobOffer}
                onClick={() => Swal.close()}
              >
                <div className="d-flex align-items-center gap-2">
                  {isLoadingDeclineJobOffer ? <Loader /> : i18next?.t('cancel')}
                </div>
              </Button>
              <Button
                type="submit"
                className="btn-bg-CTA-primary text-on-bg-primary"
                disabled={isLoadingDeclineJobOffer}
              >
                {isLoadingDeclineJobOffer ? <Loader /> : i18next?.t('decline')}
              </Button>
            </div>
          </div>
        )}
      </form>
    ),
    showConfirmButton: false,
    showClass: {
      popup: 'swal2-show',
      backdrop: 'swal2-backdrop-show modal-custom-styles',
      icon: 'swal2-icon-show',
    },
  })
}

export default DeclineJobOfferModal
