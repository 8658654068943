import { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import { PRIVATE_ROUTES } from '../../Routes/routes'

import { listOfTalents } from '../../services/talents'

import ListOfCandidates from './ListOfTalents'

import DashboardWrapper from '../Dashboard/components/DashboardWrapper'
import { useForm } from 'react-hook-form'
import Wrapper from '../../components/Wrapper/Wrapper'
import InnerBanner from '../../components/InnerBanner'
import useGoToTop from '../../hooks/useGoToTop'
import useResize from '../../hooks/useResize'
import { OUT_OF_THE_APP, VIEWPORT_LG } from '../../utils/constants'
import Filters from './components/Filters'
import { userInfo } from '../../localStorage'
import { CANDIDATES_TAGS } from '../../services/Tags/candidates'
import FiltersModal from './components/FiltersModal'
import BannerIcon from '../../icons/landing/BannerIcon'

import {
  useListOfTalentsStore,
  useSearchTalentsFiltersStore,
} from '../../store'
import { useTranslation } from 'react-i18next'
import Divider from '../../components/Divider'
import ScheduleCallBanner from '../../components/ScheduleCallBanner/ScheduleCallBanner'

const initialValues = {
  talentCategory: '',
  talentLangLevel: [],
  talentSkills: '',
  yearsExperience: [],
  keywords: '',
}

const Talents = () => {
  const { t } = useTranslation()
  const {
    data: globalData,
    page: globalPage,
    setData,
    setPage: setGlobalPage,
  } = useListOfTalentsStore()
  const { payload = {} } = userInfo
  const { typeUser = 0 } = payload

  const {
    selectedCategories,
    selectedSkills,
    selectedLanguages,
    selectedExperience,
    selectedSectors,
    selectedCountries,
    setFilterByWords,
    keywordGlobalValue,
  } = useSearchTalentsFiltersStore()

  const getOnlySkillsIds = selectedSkills?.map(({ id }) => id)
  const getOnlyCategoriesIds = selectedCategories?.map(({ id }) => id)
  const getOnlyLanguagesIds = selectedLanguages?.map(({ id }) => id)
  const getOnlyExperienceIds = selectedExperience?.map(({ id }) => id)
  const getOnlySectorIds = selectedSectors?.map(({ id }) => id)
  const getOnlyCountryIds = selectedCountries?.map(({ id }) => id)
  
  const [hasMore, setHasMore] = useState(true)
  const [selectedButton, setSelectedButton] = useState(2)
  const [scroll, setScroll] = useState(false)

  const { handleSubmit } = useForm(initialValues)

  const viewportSize = useResize()

  const handleNavigation = useCallback(() => {
    setScroll(!window.scrollY <= 0)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)
    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  const { mutate, isLoading: listOfTalentIsLoading } = useMutation(
    [CANDIDATES_TAGS.list_Of_Candidates_two],
    listOfTalents,
    {
      onSuccess: (data) => {
        if (data?.length === 0 && globalPage > 0) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        if (hasMore && data?.length > 0) {
          setData([...globalData, ...data])
          return
        }

        if (globalPage <= 0) {
          setData([...globalData, ...data])
          return
        }
      },
    }
  )

  const onSubmit = async () => {
    mutate({
      filters: {
        yearsExperience:
          getOnlyExperienceIds?.length === 0 ? '' : getOnlyExperienceIds?.[0],
        talentSkills: getOnlySkillsIds,
        talentCategory:
          getOnlyCategoriesIds?.length === 0 ? '' : getOnlyCategoriesIds,
        talentLangLevel:
          getOnlyLanguagesIds?.length === 0
            ? ''
            : String(getOnlyLanguagesIds?.[0]),
        countries: getOnlyCountryIds?.length === 0 ? [] : getOnlyCountryIds,
        sectors: getOnlySectorIds?.length === 0 ? [] : getOnlySectorIds,
        keywords: keywordGlobalValue,
      },
      page: globalPage,
    })
  }

  const handleGetMoreElements = () => {
    if (!hasMore) {
      return
    }

    if (hasMore) {
      if (window.location.pathname === PRIVATE_ROUTES.talents) {
        setGlobalPage(globalPage + 1)
      }
    }
  }

  if (window.location.pathname === PRIVATE_ROUTES.talents) {
    useGoToTop()
  }

  return (
    <Wrapper
      activeWrapper={
        window.location.pathname === PRIVATE_ROUTES.talents && typeUser === 0
      }
    >
      <DashboardWrapper
        activeWrapper={
          window.location.pathname === PRIVATE_ROUTES.talents && typeUser !== 0
        }
        dashboardWrapperClassName="w-100"
        hideDashboardWrapper
      >
        {/* TODO: refactor this component */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FiltersModal
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            page={globalPage}
            setGlobalPage={setGlobalPage}
            globalData={globalData}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
          <section
            id="list-of-talents"
            className={`${
              viewportSize[0] > VIEWPORT_LG
                ? 'd-flex flex-column align-items-center w-100'
                : ''
            } ${
              window.location.pathname === PRIVATE_ROUTES.talents
                ? viewportSize[0] > VIEWPORT_LG
                  ? 'pt-5 mt-5'
                  : 'p-2'
                : 'pt-0'
            }
          
          ${
            OUT_OF_THE_APP
              ? 'justify-content-center'
              : viewportSize[0] > VIEWPORT_LG
              ? 'p-5'
              : ''
          }
          `}
          >
            <div
              className={` 
             ${
               typeUser === 0
                 ? viewportSize[0] <= VIEWPORT_LG
                   ? ''
                   : 'w-75'
                 : 'w-100'
             }`}
            >
              {window.location.pathname === PRIVATE_ROUTES.talents &&
                typeUser === 0 && (
                  <InnerBanner
                    title={t('home')}
                    text={t('candidates')}
                    className={`d-flex ${
                      scroll ? 'mt-5 pt-3' : ''
                    } pb-3 ms-5 ps-1`}
                    beforeRoute={-1}
                  />
                )}
              {window.location.pathname === PRIVATE_ROUTES.talents &&
                OUT_OF_THE_APP && (
                  <div className="mb-5 d-flex justify-content-center align-items-center">
                    <BannerIcon
                      width={`${
                        viewportSize[0] <= VIEWPORT_LG ? '450' : '1040'
                      }`}
                      height={`${
                        viewportSize[0] <= VIEWPORT_LG ? '100' : '288'
                      }`}
                    />
                  </div>
                )}

              {typeUser !== 0 && (
                <h1 className="text-on-bg-primary fw-bolder mb-3">
                  {t('talents')}
                </h1>
              )}

              {typeUser !== 0 && (
                <Divider className="w-100 bg-on-bg-primary mb-4" />
              )}

              {typeUser !== 0 && <ScheduleCallBanner />}

              <Filters
                setSelectedButton={setSelectedButton}
                selectedCategories={selectedCategories}
                selectedSkills={selectedSkills}
                selectedLanguages={selectedLanguages}
                selectedExperience={selectedExperience}
                selectedSectors={selectedSectors}
                selectedCountries={selectedCountries}
                setFilterByWords={setFilterByWords}
                setGlobalPage={setGlobalPage}
                setData={setData}
                keywordGlobalValue={keywordGlobalValue}
              />
            </div>
            <div
              className={`${
                viewportSize[0] <= VIEWPORT_LG ? 'p-1' : 'mt-5 w-100'
              } ${
                OUT_OF_THE_APP
                  ? viewportSize[0] <= VIEWPORT_LG
                    ? ''
                    : 'w-50'
                  : ''
              }`}
            >
              <div>
                <ListOfCandidates
                  data={globalData}
                  isLoading={listOfTalentIsLoading}
                  handleGetMoreElements={handleGetMoreElements}
                  hasMore={hasMore}
                  mutate={mutate}
                  listOfTalentsPage={globalPage}
                  page={globalPage}
                  selectedButton={selectedButton}
                />
              </div>
            </div>
          </section>
        </form>
      </DashboardWrapper>
    </Wrapper>
  )
}

export default Talents
