/* eslint-disable no-unused-vars */
import InfiniteScroll from 'react-infinite-scroll-component'
import useResize from '../../../hooks/useResize'
import { useVacanciesStore } from '../../../store'
import { VIEWPORT_LG } from '../../../utils/constants'
import TalentCardSkeleton from '../../../skeleton/Landing/TalentCardSkeleton'
import VacanciesJobOfferCard from './VacanciesJobOfferCard'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import end_message_vacancies from '../../../assets/end_message_vacancies.png'
import banner_end_message_public_vacancies_mobile from '../../../assets/banner_end_message_public_vacancies_mobile.png'

const ListOfVacancies = ({
  handleGetMoreElements = () => {},
  hasMore = false,
  listOfPublicVacanciesData = [],
}) => {
  const viewportSize = useResize()
  const { id, setId } = useVacanciesStore()
  const { offerManagementId, invitationCode } = useParams()
  const navigate = useNavigate()
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  const defaultHeight = '65vh'
  const renderHeightMobile = 'auto'
  const renderHeight = isMobile ? renderHeightMobile : defaultHeight

  

  return (
    <div
      className={`${isMobile ? !id ? 'd-flex' : 'd-none' : ''} mb-lg-0 mb-3`}
      style={{
        width: isMobile ? '100%' : '387px',
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        dataLength={listOfPublicVacanciesData?.length}
        next={handleGetMoreElements}
        hasMore={hasMore}
        loader={<TalentCardSkeleton isFullWidth />}
        endMessage={
          <div className="d-flex align-items-center justify-content-center">
            <img
              className="cursor-pointer"
              src={
                isMobile
                  ? banner_end_message_public_vacancies_mobile
                  : end_message_vacancies
              }
              width={isMobile ? '100%' : 371}
              onClick={() =>
                navigate(`/register-talent-by-vacancies-link/${invitationCode}`)
              }
            />
          </div>
        }
        className="bg-skeleton-color p-lg-2 p-4 hide-scrollbar rounded"
        height={renderHeight}
      >
        {listOfPublicVacanciesData?.map((props, index) => {
          return (
            <div
              key={props?.id}
              className="cursor-pointer p-lg-2 p-0 mb-lg-0 mb-2"
              onClick={() => {
                setId(props?.id)
              }}
            >
              <VacanciesJobOfferCard
                data={listOfPublicVacanciesData}
                index={index}
                {...props}
              />
            </div>
          )
        })}
      </InfiniteScroll>
    </div>
  )
}

export default ListOfVacancies
