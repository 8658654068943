const ClockJobOfferIcon = ({ fill = '#00CF83', width = 21, height = 21 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0168 14.7527L15.4168 13.3527L11.7168 9.65273V5.05273H9.7168V10.4527L14.0168 14.7527ZM10.7168 20.0527C9.33346 20.0527 8.03346 19.7902 6.8168 19.2652C5.60013 18.7402 4.5418 18.0277 3.6418 17.1277C2.7418 16.2277 2.0293 15.1694 1.5043 13.9527C0.979297 12.7361 0.716797 11.4361 0.716797 10.0527C0.716797 8.6694 0.979297 7.3694 1.5043 6.15273C2.0293 4.93607 2.7418 3.87773 3.6418 2.97773C4.5418 2.07773 5.60013 1.36523 6.8168 0.840234C8.03346 0.315234 9.33346 0.0527344 10.7168 0.0527344C12.1001 0.0527344 13.4001 0.315234 14.6168 0.840234C15.8335 1.36523 16.8918 2.07773 17.7918 2.97773C18.6918 3.87773 19.4043 4.93607 19.9293 6.15273C20.4543 7.3694 20.7168 8.6694 20.7168 10.0527C20.7168 11.4361 20.4543 12.7361 19.9293 13.9527C19.4043 15.1694 18.6918 16.2277 17.7918 17.1277C16.8918 18.0277 15.8335 18.7402 14.6168 19.2652C13.4001 19.7902 12.1001 20.0527 10.7168 20.0527ZM10.7168 18.0527C12.9335 18.0527 14.821 17.2736 16.3793 15.7152C17.9376 14.1569 18.7168 12.2694 18.7168 10.0527C18.7168 7.83607 17.9376 5.94857 16.3793 4.39023C14.821 2.8319 12.9335 2.05273 10.7168 2.05273C8.50013 2.05273 6.61263 2.8319 5.0543 4.39023C3.49596 5.94857 2.7168 7.83607 2.7168 10.0527C2.7168 12.2694 3.49596 14.1569 5.0543 15.7152C6.61263 17.2736 8.50013 18.0527 10.7168 18.0527Z"
        fill={fill}
      />
    </svg>
  )
}

export default ClockJobOfferIcon
