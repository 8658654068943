import { token } from '../../localStorage'
import { IS_ADMIN } from '../../utils/constants'
import { BASE_URL } from '../constants'

export const getListOfCompanies = ({ page = 0, searchText = '' }) => {
  const URL = `${BASE_URL}hiring-advisor/getCompanies?page=${page}&searchText=${searchText}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const companyDetail = ({ id = 0 }) => {
  const URL = `${BASE_URL}hiring-advisor/getProfileCompany?idCompany=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfOffers = ({ id = 0, page = 0 }) => {
  const URL = `${BASE_URL}hiring-advisor/getOffersCompany?idCompany=${id}&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const offerDetail = ({ id = 0 }) => {
  const URL = `${BASE_URL}hiring-advisor/getOneOffer?idOffer=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const stateSendOffers = ({ id = 0, keyword = 'all' }) => {
  const URL = `${BASE_URL}hiring-advisor/getStatesSendOffers?idOffer=${id}&keyword=${keyword}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createFastJobOfferByHa = (data) => {
  const URL = `${BASE_URL}order/createOfferReferido`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfJobsOffersByHa = ({ page = 0 }) => {
  const URL = `${BASE_URL}hiringAdvisor/getAllOffersOrder?page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const jobOfferDetailByHa = ({ id = 0 }) => {
  const adminEndPoint = `master/getOneOfferOrder?idOffer=${id}`
  const hiringAdvisorEndPoint = `hiringAdvisor/getOneOffersOrder?idOffer=${id}`

  const URL = `${BASE_URL}${IS_ADMIN ? adminEndPoint : hiringAdvisorEndPoint}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const matchJobOfferHa = ({ id = 0 }) => {
  const URL = `${BASE_URL}hiringAdvisor/getMatchOfferOrder?idOffer=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOffersByHiringAdvisor = ({ page = 0, searchText = '', companyId = '' }) => {
  const URL = `${BASE_URL}user/listOffersByHiringAdvisor?page=${page}&category&searchText=${searchText}&dateBefore&dateAfter=&companyId=${companyId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listSentOffersByHiringAdvisor = ({
  page = 0,
  searchText = '',
  category = '',
  companyId = ''
}) => {
  const URL = `${BASE_URL}user/listSentOffersByHiringAdvisor?page=${page}&category=${category}&searchText=${searchText}&dateBefore&dateAfter=&companyId=${companyId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getDetailProcessByOfferHA = ({ offerId = 0, companyId = '' }) => {
  const URL = `${BASE_URL}user/getDetailProcessByOffer?offerId=${offerId}&companyId=${companyId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getColumnsProcessByOfferHA = ({ offerId = 0, companyId = '' }) => {
  const URL = `${BASE_URL}user/getColumnsProcessByOffer?companyId=${companyId}&offerId=${offerId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const panelCountersOfferHiringAdvisor = () => {
  const URL = `${BASE_URL}user/panelCountersOfferHiringAdvisor`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}
