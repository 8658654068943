import { useEffect, useRef, useState } from 'react'

import {
  IS_ADMIN,
  IS_HIRING_ADVISOR,
  ITEMS_PER_PAGE,
  NOT_AVAILABLE,
  OUT_OF_THE_APP,
  VIEWPORT_LG,
} from '../../utils/constants'

import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import HeartIconTwo from '../../icons/HeartIconTwo'
import Divider from '../../components/Divider'
import Badge from '../../components/Badge/Badge'
import CalendarIcon from '../../icons/CalendarIcon'
import TranslateIconTwo from '../../icons/TranslateIconTwo'
import { getMyOffersManagement } from '../../services/OfferManagement'
import { TAGS } from '../../services/Tags'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import SearchUserOutlineExtraSmallIcon from '../../icons/SearchUserOutlineExtraSmallIcon'
import Button from '../../components/Button'
import PlusSmallIcon from '../../icons/PlusSmallIcon'
import Swal from 'sweetalert2'
import Pagination from '../../components/Pagination'
import HeartRedIcon from '../../icons/HeartRedIcon'
import {
  addMultipliesFavorites,
  removeFavorite,
} from '../../services/Favorites'
import {
  addFavoriteByAdmin,
  getListOfValidateFastOffers,
  removeFavoriteByAdmin,
} from '../../services/Admin'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PdfToDownload } from '../pdf/PdfToDownload'
import withReactContent from 'sweetalert2-react-content'
import PdfIcon from '../../icons/PdfIcon'
import { useTranslation } from 'react-i18next'
import { useListOfTalentsBySuperSearchStore } from '../../store'
import { CALENDLY_API_URL } from '../../services/constants'
import Loader from '../../components/Loader'
import Toast from '../../components/Toast/Toast'
import useResize from '../../hooks/useResize'

const TalentCard = ({
  talen_skills = [],
  name_category_job = '',
  languaje_lang_levels = {},
  favorites_v2s = null,
  favorites_offers_orders = null,
  isFavorite = true,
  showStatus = true,
  years = {},
  create_date = '',
  user = {},
  views = [],
  id: idTalent = '',
  data,
  isBanner = false,
  bannerUrl = '',
}) => {
  const collapseRef = useRef({})
  const { t } = useTranslation()
  const viewportSize = useResize()
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id } = useListOfTalentsBySuperSearchStore()
  const totalElementsToShow = 3
  const showSkills = talen_skills?.length > totalElementsToShow
  const notHaveFavorites = favorites_v2s?.length !== 0
  const transformCheckOffersFavoriteData = favorites_v2s?.map(
    ({ offer_id }) => offer_id
  )
  const [selectedIds, setSelectedIds] = useState(favorites_v2s ?? [])
  const [checkOffersFavorite, setCheckOffersFavorite] = useState(
    transformCheckOffersFavoriteData ?? []
  )
  const [search, setSearch] = useState('')
  const [newFavorite, setNewFavorite] = useState(Boolean(notHaveFavorites))
  const [page, setPage] = useState(0)

  const elementsToShow = talen_skills?.slice(0, totalElementsToShow)
  const talentDate = dayjs(create_date)?.format('DD-MM-YYYY')
  const today = dayjs().format('DD-MM-YYYY')
  const isMobile = viewportSize[0] <= VIEWPORT_LG

  const { data: companyOffersData = [] } = useQuery(
    [TAGS.offers_company, page, search],
    () =>
      IS_ADMIN
        ? getListOfValidateFastOffers({ page })
        : getMyOffersManagement({ page, search }),
    {
      enabled: !OUT_OF_THE_APP,
    }
  )

  const [listOfJobsOffer, setListOfJobsOffer] = useState(companyOffersData)

  const {
    mutate: addFavoriteMutate,
    isLoading: isLoadingAddFavorite,
    variables: addFavoriteVariables,
    reset: addFavoriteReset,
  } = useMutation(
    [TAGS.add_favorite],
    IS_ADMIN ? addFavoriteByAdmin : addMultipliesFavorites,
    {
      onSuccess: ({ favorites }) => {
        const notHaveFavorites = selectedIds?.length !== 0
        const transformDataTest = favorites?.map(({ offer_id }) => offer_id)
        setSelectedIds(favorites)
        setCheckOffersFavorite(transformDataTest)
        setNewFavorite(Boolean(notHaveFavorites))
        addFavoriteReset()
        Toast({ title: 'Agregado a favoritos exitosamente' })
        queryClient.invalidateQueries(TAGS.add_favorite)
      },
    }
  )

  const {
    mutate: removeFavoriteMutate,
    isLoading: isLoadingRemoveFavorite,
    variables: removeFavoriteVariables,
    reset: removeReset,
  } = useMutation(
    [TAGS.remove_favorite],
    IS_ADMIN ? removeFavoriteByAdmin : removeFavorite,
    {
      onSuccess: ({ favorites }) => {
        const notHaveFavorites = selectedIds?.length !== 0
        const transformDataTest = favorites?.map(({ offer_id }) => offer_id)
        setSelectedIds(favorites)
        setCheckOffersFavorite(transformDataTest)
        setNewFavorite(Boolean(notHaveFavorites))
        removeReset()
        Toast({ title: 'Removido de favoritos exitosamente' })
        queryClient.invalidateQueries(TAGS.remove_favorite)
      },
    }
  )

  const handleOfferSelect = ({ id }) => {
    const filterIds = favorites_offers_orders?.find(
      ({ offert_id }) => offert_id === id
    )
    const transformData = selectedIds?.map(({ offer_id }) => offer_id)
    const transformDataToRemoveFavorite = selectedIds.find(
      ({ offer_id }) => offer_id === id
    )?.id

    const companyData = {
      idTalent,
      idsOffer: [Number(id)],
    }

    const adminData = {
      idTalent,
      idOffer: id,
    }

    const companyRemoveId = {
      idTalent,
      IdsFavorites: [transformDataToRemoveFavorite],
      idsOffer: [Number(id)],
    }

    const adminRemoveId = {
      id: filterIds?.id,
    }

    const renderData = IS_ADMIN ? adminData : companyData
    const renderRemoveFavoriteData = IS_ADMIN ? adminRemoveId : companyRemoveId

    if (checkOffersFavorite?.includes(id)) {
      if (checkOffersFavorite?.length === 0) {
        setNewFavorite(false)
      }
      removeFavoriteMutate(renderRemoveFavoriteData)
      setSelectedIds(checkOffersFavorite?.filter((prop) => prop !== id))
    } else {
      setNewFavorite(true)
      setSelectedIds([...transformData, id])
      addFavoriteMutate(renderData)
    }
  }

  const handleGoToCreateJobOffer = () => {
    navigate(`/create-job-offer/${idTalent}`)
  }

  const handleGoToReviewProfile = () => {
    navigate(`/talent/${idTalent}`)
  }

  const handleDownloadPdf = (talentId) => {
    const findInformation = data?.find(
      (props) => Number(props?.id) === Number(talentId)
    )

    MySwal.fire({
      title: <PdfIcon />,
      showConfirmButton: false,
      html: (
        <>
          <PDFDownloadLink
            document={<PdfToDownload data={findInformation} />}
            fileName={`${findInformation?.user.username}_work_experience.pdf`}
          >
            <Button>{t('download_pdf')}</Button>
          </PDFDownloadLink>
        </>
      ),
    })
  }

  const handleCollapse = () => {
    const currentCollapse = collapseRef.current
    const allCollapses = document.querySelectorAll('.collapse')

    allCollapses.forEach((collapse) => {
      if (collapse !== currentCollapse && collapse.classList.contains('show')) {
        collapse.classList.remove('show')
      }
    })

    currentCollapse.classList.toggle('show')
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setListOfJobsOffer(companyOffersData)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [companyOffersData])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const currentCollapse = collapseRef.current
      const isClickInsideCollapse =
        currentCollapse && currentCollapse.contains(event.target)
      const isCheckboxClick =
        event.target.tagName === 'INPUT' && event.target.type === 'checkbox'

      if (
        currentCollapse &&
        currentCollapse.classList.contains('show') &&
        !isClickInsideCollapse &&
        !isCheckboxClick
      ) {
        currentCollapse.classList.remove('show')
      }
    }

    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  return isBanner ? (
    <Link target="_blank" to={CALENDLY_API_URL}>
      <img src={bannerUrl ?? ''} width="100%" className="mb-2" />
    </Link>
  ) : (
    <div
      className={`${
        id === idTalent
          ? 'border-bg-CTA-primary bg-bg-success-pressed'
          : 'bg-white'
      } zoom border rounded mb-2 text-on-bg-primary p-2 position-relative`}
      style={{
        width: '100%',
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <span className="fw-bold">{name_category_job || NOT_AVAILABLE}</span>
          <span className="font-small">{user?.username}</span>
        </div>
        <div className="d-flex align-items-center gap-2">
          {showStatus && talentDate === today && (
            <span className="badge bg-bg-brand rounded-pill p-2">
              {t('new')}
            </span>
          )}
          {showStatus && views.length > 0 && (
            <span className="badge  bg-on-bg-CTA-primary-selected  text-white rounded-pill p-2">
              {t('seen')}
            </span>
          )}
          <Badge className="border text-on-bg-Success bg-bg-Success rounded-pill p-2">
            {t('available')}
          </Badge>
          {isFavorite && (
            <div>
              {' '}
              {!OUT_OF_THE_APP && (
                <>
                  {!IS_HIRING_ADVISOR && (
                    <div
                      className="p-1 cursor-pointer"
                      title={'Agregar a favoritos'}
                      data-bs-toggle="collapse"
                      data-bs-target={`#list-of-job-offers-collapse-${idTalent}`}
                      onClick={handleCollapse}
                    >
                      {newFavorite ? <HeartRedIcon /> : <HeartIconTwo />}
                    </div>
                  )}
                  <div
                    id={`list-of-job-offers-collapse-${idTalent}`}
                    className="collapse border rounded bg-white shadow-sm"
                    ref={collapseRef}
                    style={{
                      position: 'absolute',
                      right: 0,
                      zIndex: 30,
                      width: isMobile ? '100%' : '60%',
                    }}
                  >
                    <div className="px-3 pt-3">
                      <span className="text-on-bg-secondary">
                        {t('save_as_favorite')}
                      </span>
                      <input
                        className="form-label rounded border border-2 w-100 mb-2 mt-2"
                        placeholder={t('search_offer')}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <ul className="list-unstyled">
                        {listOfJobsOffer?.offers?.map(
                          ({ id, name, position, countTalentLinked }) => {
                            const loadings =
                              isLoadingAddFavorite || isLoadingRemoveFavorite
                            const selectedId =
                              addFavoriteVariables?.idsOffer[0] === id ||
                              removeFavoriteVariables?.idsOffer[0] === id
                            const renderLoading = loadings && selectedId

                            return (
                              <li
                                key={id}
                                className="d-flex justify-content-between align-items-center gap-2 mb-1"
                              >
                                {renderLoading ? (
                                  <Loader />
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        width: '100%',
                                        overflow: isMobile ? '' : 'auto',
                                      }}
                                      className="d-flex align-items-center gap-2"
                                    >
                                      <input
                                        type="checkbox"
                                        className="mycheck"
                                        checked={checkOffersFavorite?.includes(
                                          id
                                        )}
                                        onChange={() =>
                                          handleOfferSelect({
                                            id,
                                          })
                                        }
                                      />
                                      <span
                                        style={{
                                          fontSize: isMobile ? '12px' : '16px',
                                        }}
                                        className="text-decoration-none text-on-bg-primary inline-text text-truncate"
                                      >
                                        {name} | {position}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center bg-bg-tertiary rounded-pill rounded-2 gap-2 px-1">
                                      <SearchUserOutlineExtraSmallIcon />
                                      <span className="font-extra-small text-on-bg-secondary">
                                        {countTalentLinked}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </li>
                            )
                          }
                        )}
                        <div className="mt-2 d-flex align-items-center justify-content-center">
                          <Pagination
                            page={page}
                            setPage={setPage}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={companyOffersData?.offersCount}
                          />
                        </div>
                      </ul>
                    </div>
                    {!IS_ADMIN && (
                      <div className="list-of-job-offers-button-container p-3 d-flex justify-content-center w-100">
                        <Button
                          className="d-flex align-items-center gap-1 bg-white border border-on-bg-primary"
                          onClick={handleGoToCreateJobOffer}
                        >
                          <PlusSmallIcon />
                          <span className="text-on-bg-primary">
                            {t('create_new_offer')}
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Divider className="bg-on-bg-Tertiary w-100 mt-2 mb-2" />

      <div className="d-flex flex-column align-items-start gap-1 font-small-2">
        <div className="d-flex align-items-center gap-1 inline-text overflow-auto">
          <CalendarIcon />
          <span className="text-on-bg-secondary">{t('experience')}:</span>
          <span>{years?.replace('años', t('years'))}</span>
          {t('from_experience')}
        </div>
        {languaje_lang_levels?.length > 1 ? (
          <div className="d-flex align-items-center gap-2 inline-text overflow-auto">
            <TranslateIconTwo />
            <span className="text-on-bg-secondary">{t('languages')}:</span>
            <span className="text-on-bg-secondary">
              {languaje_lang_levels?.length} {t('languages')}
            </span>
          </div>
        ) : (
          <div className="d-flex align-items-center inline-text overflow-auto">
            <TranslateIconTwo />
            <span className="ms-1 font-small-2">
              {languaje_lang_levels[0]?.languaje?.name}{' '}
              {languaje_lang_levels[0]?.lang_level?.name}
            </span>
          </div>
        )}
      </div>

      <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between  position-relative">
        <div className="d-flex align-items-baseline overflow-auto mt-2">
          {elementsToShow.map(({ skills_categori, id }) => (
            <Badge
              key={id}
              className="border border-on-bg-CTA-primary-selected text-on-bg-CTA-primary-selected rounded-pill me-2 font-small-3"
            >
              {skills_categori?.skill?.name || NOT_AVAILABLE}
            </Badge>
          ))}
          {showSkills && (
            <Badge
              key={idTalent}
              className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2 font-small-3"
            >
              {`+${Number(talen_skills?.length) - Number(totalElementsToShow)}`}
            </Badge>
          )}
        </div>
        {!OUT_OF_THE_APP && (
          <div className="mt-3 mt-lg-0 d-flex justify-content-end align-items-center gap-3 position-relative">
            {(IS_ADMIN || IS_HIRING_ADVISOR) && (
              <Button type="button" onClick={() => handleDownloadPdf(idTalent)}>
                <div className="d-flex align-items-center gap-2">
                  <span className="font-small-2 inline-text">
                    {t('download_pdf')}
                  </span>
                </div>
              </Button>
            )}
            <Button
              type="button"
              className="btn-white text-on-bg-primary border border-on-bg-primary"
              onClick={handleGoToReviewProfile}
            >
              <div className="d-flex align-items-center gap-2">
                <span className="font-small inline-text">
                  {t('review_profile')}
                </span>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default TalentCard
