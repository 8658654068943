import { useState } from 'react'
import { IS_HIRING_ADVISOR, NOT_AVAILABLE } from '../../../utils/constants'
import Badge from '../../../components/Badge/Badge'
import { useTranslation } from 'react-i18next'
import ArrowDownSmallIcon from '../../../icons/ArrowDownSmallIcon'
import ArrowUpIcon from '../../../icons/ArrowUpIcon'

const GeneralSkills = ({ skills }) => {
  const { t } = useTranslation()

  const totalElementsToShow = 11
  const showButton = skills?.length > totalElementsToShow

  const elementsToShow = skills?.slice(0, totalElementsToShow) ?? []

  const [showAllSkills, setShowAllSkills] = useState(false)

  const renderGeneralSkills = showAllSkills ? skills : elementsToShow

  const handleShowAllSkills = () => {
    setShowAllSkills((prevState) => !prevState)
  }

  return (
    <div className="w-100">
      <span className="fw-bold text-on-bg-primary">{t('required_skills')}</span>
      <div className="bg-white mt-2 p-2 mb-1 rounded">
        <div className="d-flex flex-column justify-content-start align-items-start w-100">
          <div id="list-of-skills" className="p-2 ms-lg-3">
            {renderGeneralSkills?.map(({ id, skills_categori }) => {
              return (
                <Badge
                  key={id}
                  className="border border-on-bg-CTA-primary-selected text-on-bg-CTA-primary-selected rounded-pill me-2 mt-2"
                >
                  <span className="font-small">
                    {skills_categori?.skill?.name || NOT_AVAILABLE}
                  </span>
                </Badge>
              )
            })}
          </div>
          {showButton && !IS_HIRING_ADVISOR && (
            <div
              className="d-flex justify-content-center align-items-center gap-1 w-100 cursor-pointer"
              onClick={handleShowAllSkills}
            >
              <span className="text-on-bg-secondary">
                {showAllSkills ? t('see_less') : t('see_all_skills')}
              </span>
              {showAllSkills ? (
                <ArrowUpIcon fill="#004949" />
              ) : (
                <ArrowDownSmallIcon fill="#004949" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GeneralSkills
